<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer">
            <div class="box">
                <div class="columns">
                    <div class="column is-10">
                        <div class="columns">
                            <div class="column is-2">
                                <b-field :label="$t('GUIAATENDIMENTO.IDENTIFICACAO')">
                                    <b-input type="text" v-model="guia.id" readonly></b-input>
                                </b-field>
                            </div>
                            <div class="column is-2">
                                <b-field :label="$t('GUIAATENDIMENTO.REFERENCIA')">
                                    <b-input :disabled="guia.id != 0 &&  !isInRole('guia-alterar-referencia')" type="text" v-model="guia.referencia" autocomplete="no"></b-input>
                                </b-field>
                            </div>
                            <div class="column is-1">
                                <b-field :label="$t('GUIAATENDIMENTO.PAGAMENTO')">
                                    <b-tag v-if="guia.paga" size="is-medium" type="is-success">{{$t('GUIAATENDIMENTO.REALIZADO')}}</b-tag>
                                    <b-tag v-else-if="!guia.paga && guia.autorizacao" size="is-medium" type="is-warning">{{$t('GUIAATENDIMENTO.AUTORIZADO')}}</b-tag>
                                    <b-tag v-else-if="!guia.paga" size="is-medium" type="is-danger">{{$t('GUIAATENDIMENTO.PENDENTE')}}</b-tag>
                                </b-field>
                            </div>
                            <div class="column is-1 has-text-centered">
                                <br>
                                <b-tooltip :label="$t('GUIAATENDIMENTO.INCIDENCIA')" placement="bottom" position="is-bottom" type="is-warning">
                                    <b-icon v-if="guia.incidencia"
                                            icon="alert-rhombus"
                                            size="is-medium"
                                            class="is-pulled-right"
                                            type="is-warning"></b-icon>
                                </b-tooltip>
                            </div>

                            <div class="column is-4">
                                <searchIdName :preFetch="true" :listWhenEmpty="true" :id.sync="guia.localDeOrigemId" @item="escolheuLocal" :label="$t('GUIAATENDIMENTO.LOCALORIGEM')" table="local"></searchIdName>
                            </div>
                            <div class="column is-2">
                                <b-field :label="$t('GUIAATENDIMENTO.EMITIDA')">
                                    <b-datetimepicker v-model="guia.dataDeEmissao"></b-datetimepicker>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns">
                                <searchIdName class="column is-8" :id.sync="guia.pacienteId" :trazDesativado="true" :label="$t('SISTEMA.PACIENTE')" table="Paciente" :leitura="true">

                                    <slot>
                                        <b-tooltip :label="$t('GUIAATENDIMENTO.INFOPACIENTE')" placement="bottom" position="is-bottom" type="is-primary">
                                            <b-button icon-left="square-edit-outline"
                                                      tag="router-link"
                                                      :to="{ name: 'paciente', params: { id: guia.pacienteId, return: true, agendamentoDashboard: agendamentoDashboard, convenioIdAgendar: guia.convenioId }}"></b-button>
                                        </b-tooltip>

                                        <b-tooltip :label="$t('GUIAATENDIMENTO.GUIASPACIENTE')" placement="bottom" position="is-bottom" type="is-primary">
                                            <b-button icon-left="clipboard-text-multiple-outline"
                                                      tag="router-link"
                                                      :to="{ name: 'guia-list', query: { pacienteId: guia.pacienteId, guiaId: guia.id }, params: { agendamentoDashboard: agendamentoDashboard, convenioIdAgendar: guia.convenioId }}"></b-button>
                                        </b-tooltip>

                                    </slot>

                                </searchIdName>
                            
                            <div class="column is-2">
                                <b-field label="D.U.M." v-show="guia.pacienteSexo == 'F'">
                                    <b-datetimepicker v-model="guia.dataDaUltimaMenstruacao"></b-datetimepicker>
                                </b-field>
                            </div>
                            <div class="column is-2">
                                <b-field :label="$t('GUIAATENDIMENTO.LEITO')">
                                    <b-input type="text" v-model="guia.leito" autocomplete="no"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-4">
                                <span v-show="guia.pacienteInscricao"><small>{{$t('GUIAATENDIMENTO.INSCRICAO')}} <b><span>{{guia.pacienteInscricao}}</span></b></small></span>
                            </div>
                            <div class="column is-5">
                                <span v-show="guia.pacienteEspecie">
                                    <small>{{$t('GUIAATENDIMENTO.ESPECIE')}}
                                        <b>
                                            <span>{{guia.pacienteEspecie}}</span>
                                        </b>
                                    </small>
                                </span>
                                &nbsp;&nbsp;
                                <span v-show="guia.pacienteSexo">
                                    <small>{{$t('GUIAATENDIMENTO.SEXO')}}
                                        <b>
                                            <span>{{guia.pacienteSexo}}</span>
                                        </b>
                                    </small>
                                </span>
                                &nbsp;&nbsp;
                                <span v-show="guia.pacienteDataDeNascimentoIdade && !guia.pacienteDataDeNascimentoIdade.includes('01/01/0001')">
                                    <small>{{$t('GUIAATENDIMENTO.NASCIMENTO')}}
                                        <b>
                                            <span>{{guia.pacienteDataDeNascimentoIdade}}</span>
                                        </b>
                                    </small>
                                </span>
                            </div>
                        </div>
                        <div class="columns">
                            <searchIdName v-if="$route.params.convenioIdAgendar" class="column is-8" :id.sync="guia.convenioId" @item="escolheuConvenio" :label="$t('SISTEMA.CONVENIO')" table="Convenio"
                                          :leitura="guia.id != 0 && (exames.length == 0 || (exames.every(x => x.cancelado || x.faturado))) || !isInRole('guia-alteraconvenio')">
                                <slot>

                                    <b-tooltip :label="$t('GUIAATENDIMENTO.ABRIRINFORMACOESCONVENIO')" placement="bottom" position="is-bottom" type="is-dark">
                                        <b-button icon-left="square-edit-outline"
                                                  tag="router-link"
                                                  :to="{ name: 'convenio', params: { id: guia.convenioId, guia: true, return: true, agendamentoDashboard: agendamentoDashboard, convenioIdAgendar: guia.convenioId } }"></b-button>
                                    </b-tooltip>


                                    <b-tooltip :label="$t('GUIAATENDIMENTO.ABRIRDESCRICAOCONVENIO')" placement="bottom" position="is-bottom" type="is-dark">
                                        <b-button v-if="guia.convenioId"
                                                  icon-left="information"
                                                  @click="abreDescricaoConvenio()"></b-button>
                                    </b-tooltip>
                                </slot>
                            </searchIdName>
                            <searchIdName v-else class="column is-8" :infoConvenio="true" :id.sync="guia.convenioId" @item="escolheuConvenio" :label="$t('SISTEMA.CONVENIO')" table="Convenio" 
                                :campos="[{nome:'Razão Social', campo: 'razaoSocial'}, {nome:'Endereço', campo: 'endereco'}]"
                                :leitura="guia.id != 0 && (exames.length == 0 || (exames.every(x => x.cancelado || x.faturado)))
                                || !isInRole('guia-alteraconvenio') && guia.id != 0" autocomplete="no">
                                <slot>
                                    <b-tooltip :label="$t('GUIAATENDIMENTO.ABRIRINFORMACOESCONVENIO')" placement="bottom" position="is-bottom" type="is-dark">
                                        <b-button icon-left="square-edit-outline"
                                                  tag="router-link"
                                                  :to="{ name: 'convenio', params: { id: guia.convenioId, guia: true, return: true, agendamentoDashboard: agendamentoDashboard } }"></b-button>
                                    </b-tooltip>

                                    <b-tooltip :label="$t('GUIAATENDIMENTO.ABRIRDESCRICAOCONVENIO')" placement="bottom" position="is-bottom" type="is-dark">
                                        <b-button :disabled="!guia.convenioId"
                                                  icon-left="information"
                                                  @click="abreDescricaoConvenio()"></b-button>
                                    </b-tooltip>

                                </slot>
                            </searchIdName>
                            <div class="column is-2">
                                <b-field :label="$t('GUIAATENDIMENTO.HORASJEJUM')" expanded>
                                    <b-tooltip expanded :label="$t('GUIAATENDIMENTO.CALCULARHORASJEJUM')"
                                        type="is-primary"
                                        position="is-top" >
                                        <b-input type="text" v-mask="['##:##']" icon="forward" v-model="guia.horasDeJejum" ref="horasDeJejum" icon-clickable @icon-click="abrirAlterarDataUltimaRefeicao" expanded></b-input>                                            
                                    </b-tooltip>
                                </b-field>
                            </div>
                            <div class="column is-2">
                                <b-field :label="$t('GUIAATENDIMENTO.ALTA')">
                                    <b-datetimepicker v-model="guia.alta"></b-datetimepicker>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns" v-bind:class="{ 'has-background-warning': incluiuNovoMedico }">
                            <searchIdName class="column is-8" :id.sync="guia.medicoId" :label="$t('SISTEMA.SOLICITANTE')" table="Medico"
                            :campos="[{nome:'Num. Conselho', campo: 'conselhoNumero', observacao: 'observacao'}]"  @item="escolheuMedico">
                                <slot>
                                    <b-tooltip :label="$t('GUIAATENDIMENTO.ABRIRINFORMACOES')" placement="bottom" position="is-bottom" type="is-primary">
                                        <b-button icon-left="square-edit-outline"
                                                  tag="router-link"
                                                  :to="{ name: 'medico', params: { id: guia.medicoId, return: true, agendamentoDashboard: agendamentoDashboard, convenioIdAgendar: guia.convenioId, pacienteId: guia.pacienteId } }"></b-button>
                                    </b-tooltip>

                                    <b-tooltip :label="$t('GUIAATENDIMENTO.INCLUIRMEDICO')" placement="bottom" position="is-bottom" type="is-primary">
                                        <b-button icon-left="plus-box"
                                                  tag="router-link"
                                                  target="_blank"
                                                  :to="{ name: 'medico',  params: { return: true },query: { retornarMedico:true}  }"></b-button>
                                    </b-tooltip>
                                </slot>
                            </searchIdName>
                            <div class="column is-2">
                                <b-field label="CBO-S">
                                    <b-select v-model="guia.medicoCBOSId" expanded>
                                        <option v-for="(cbos,index) in medicoCboss"
                                                :key="index"
                                                :value="cbos.id">
                                            {{ cbos.codigo }}
                                        </option>
                                    </b-select>
                                </b-field>
                            </div>
                            <div class="column is-2">
                                <b-field :label="$t('GUIAATENDIMENTO.RETORNO')">
                                    <b-datetimepicker v-model="guia.dataDeRetorno"></b-datetimepicker>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="column is-2">
                        <guiaindicadores :guia="guia" editavel></guiaindicadores>
                        <b-field style="margin-top: 10px;" v-if="alterandoUnidade"  label="Unidade"  label-position="on-border" >
                            <b-select size="is-small" v-model="guia.unidadeDeCadastroId" expanded>
                                <option v-for="(item, index) in unidades"  v-bind:key="index"  :value="item.id">{{item.nome}}</option>
                            </b-select>
                        </b-field>

                        <div v-else class="columns"  >
                            <div class="column is-12">
                                <p class="form-control-static">

                                    {{$t('GUIAATENDIMENTO.UNIDADE')}} <b>{{unidadeNome}}</b>
                                <b-tooltip :label="$t('GUIAATENDIMENTO.ALTERARUNIDADE')" placement="bottom" position="is-bottom" type="is-light">
                                    <a v-if="isInRole('alterar-unidade-atual')" href="#" class="has-text-grey" @click="alterandoUnidade = true">
                                        <b-icon icon="pencil" size="is-small"></b-icon>
                                    </a>   
                                </b-tooltip>

                                </p>
                            </div>
                        </div>
                        <b-field style="margin-top: 10px;" v-if="alterandoCodigoIntegracaoGuia"  label="Integrador"  label-position="on-border" >
                            <b-select size="is-small" v-model="guia.integradorId" expanded>
                                <option v-for="(item, index) in integradores"  v-bind:key="index"  :value="item.id">{{item.nome}}</option>
                            </b-select>
                        </b-field>      
                        <div v-else class="columns"  >
                            <div class="column is-12">
                                <p class="form-control-static">
                                    {{$t('GUIAATENDIMENTO.INTEGRADOR')}} <b>{{codigoIntegracaoNome}}</b>
                                <b-tooltip :label="$t('GUIAATENDIMENTO.ALTERARINTEGRADOR')" placement="bottom" position="is-bottom" type="is-light">
                                    <a v-if="isInRole('guia-alterar-dadosIntegracao')" href="#" class="has-text-grey" @click="alterandoCodigoIntegracaoGuia = true">
                                        <b-icon icon="pencil" size="is-small"></b-icon>
                                    </a>   
                                </b-tooltip>
                                </p>
                            </div>
                        </div>
                        <div class="columns" v-if="guia.tipoAgendamentoNome != null"  >
                            <div class="column is-12">
                                <p class="form-control-static">
                                    {{$t('GUIAATENDIMENTO.TIPOAGENDAMENTO')}}<b>{{guia.tipoAgendamentoNome}}</b>  
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="columns">
                    <div class="column is-10">
                        <b-tabs position="is-right" type="is-boxed" @input="mudouItemTab" v-model="activeItemTab" multiline>
                            <b-tab-item>
                                <template slot="header">
                                    <span @click="selecionarTodosExames()">{{$t('GUIAATENDIMENTO.EXAMES')}} <b-tag v-if="exames" type="is-info" rounded> {{ exames.length }} </b-tag> </span>
                                </template>
                                <div class="columns is-multiline">
                                    <guiaExame v-for="(exame, index) in exames" :key="index" :guia="guia" :guiaexame="exame" :index="index" v-on:excluirExame="excluirExame" :selecionado="exameSelecionado.includes(exame)" v-on:selecionarExame="selecionarExame(exame)" v-on:loadPrecosExames="loadPrecosExames()" v-on:observacaoMudou="atualizaPrecoQuandoMudaQuantidade(exame)" :validado="exame.resultadoValidado"></guiaExame>
                                </div>
                            </b-tab-item>
                            <b-tab-item v-if="amostras.length > 0">
                                <template slot="header">
                                    <span @click="selecionarTodasAmostras()">{{$t('GUIAATENDIMENTO.AMOSTRAS')}} <b-tag v-if="amostras" type="is-info" rounded> {{ amostras.length }} </b-tag> </span>
                                </template>
                                <div class="columns is-multiline">
                                    <guiaAmostra v-for="(amostra, index) in amostras" :key="index" :amostra="amostra" :index="index" :mostra-paciente="false" :selecionada="amostraSelecionada.includes(amostra)" v-on:selecionarAmostra="selecionarAmostra(amostra)"></guiaAmostra>
                                </div>
                            </b-tab-item>
                            <b-tab-item v-if="estudos.length > 0">
                                <template slot="header">
                                    <span>{{$t('GUIAATENDIMENTO.ESTUDOS')}} <b-tag v-if="estudos" type="is-info" rounded> {{ estudos.length }} </b-tag> </span>
                                </template>
                                <div class="columns is-multiline">
                                    <estudo v-for="(estudo, index) in estudos" :key="index" :estudo="estudo" :index="index" :selecionado="estudoSelecionado.includes(estudo)" v-on:selecionarEstudo="selecionarEstudo(estudo)" :desvincular="true"></estudo>
                                </div>
                            </b-tab-item>
                        </b-tabs>
                        <div class="columns" v-show="isIncluindoExame">
                            <div class="column is-9" v-show="showIncluirExame">
                                <nav class="panel">
                                    <p class="panel-heading">
                                        {{$t('GUIAATENDIMENTO.INCLUIREXAME')}}
                                        <button type="button" class="delete is-pulled-right" aria-label="delete" @click="isIncluindoExame=!isIncluindoExame"></button>
                                    </p>
                                    <div class="panel-block">
                                        <div class="column is-5.5 pr-0">
                                            <b-input v-model="procurarExameTexto"
                                                    icon="magnify"
                                                    @input="inputListaExames"
                                                    @keydown.native.esc="isIncluindoExame=false"
                                                    @keydown.native.down="incluindoExameIndex++"
                                                    @keydown.native.up="incluindoExameIndex--"
                                                    @keydown.native.enter.prevent="incluirExame(listaExames[incluindoExameIndex])"
                                                    ref="procurarExameTextoInput">
                                            </b-input>
                                        </div>
                                        <b-dropdown aria-role="list"
                                                    position="is-bottom-left"
                                                    v-model="procurarExamePor"
                                                    @input="$refs.procurarExameTextoInput.focus()">

                                            <button class="button" slot="trigger" slot-scope="{ active }">
                                                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                                            </button>
                                            <b-dropdown-item aria-role="menu-item" custom>
                                                <small>{{$t('GUIAATENDIMENTO.PROCURARPOR')}}</small>
                                            </b-dropdown-item>
                                            <b-dropdown-item aria-role="listitem" value="apelido">
                                                {{$t('GUIAATENDIMENTO.APELIDO')}}
                                            </b-dropdown-item>
                                            <b-dropdown-item aria-role="listitem" value="Nome">
                                                {{$t('GUIAATENDIMENTO.NOME')}}
                                            </b-dropdown-item>
                                            <b-dropdown-item aria-role="listitem" value="Codigo Externo">
                                                {{$t('GUIAATENDIMENTO.CODIGOEXTERNO')}}
                                            </b-dropdown-item>
                                            <b-dropdown-item aria-role="listitem" value="Id">
                                                {{$t('GUIAATENDIMENTO.IDENTIFICACAO')}}
                                            </b-dropdown-item>
                                        </b-dropdown>
                                        <div class="column is-3 pl-3 pr-0">
                                            <b-input v-model="procurarMaterialTexto"
                                                    icon="magnify"
                                                    :placeholder="$t('GUIAATENDIMENTO.MATERIAL')"
                                                    @input="inputListaExames"
                                                    @keydown.native.esc="isIncluindoExame=false"
                                                    @keydown.native.down="incluindoExameIndex++"
                                                    @keydown.native.up="incluindoExameIndex--"
                                                    @keydown.native.enter.prevent="incluirExame(listaExames[incluindoExameIndex])">
                                            </b-input>
                                        </div>
                                        <div class="column is-3 pl-3">
                                            <b-input v-model="procurarConservanteTexto"
                                                    icon="magnify"
                                                    :placeholder="$t('GUIAATENDIMENTO.CONSERVANTE')"
                                                    @input="inputListaExames"
                                                    @keydown.native.esc="isIncluindoExame=false"
                                                    @keydown.native.down="incluindoExameIndex++"
                                                    @keydown.native.up="incluindoExameIndex--"
                                                    @keydown.native.enter.prevent="incluirExame(listaExames[incluindoExameIndex])">
                                            </b-input>
                                        </div>
                                    </div>
                                    <b-message v-if="!listaExames && !grupoExames">{{$t('GUIAATENDIMENTO.NENHUMEXAMEDISPONIVEL')}}</b-message>
                                    <template v-for="(exame, index) in listaExames" >
                                        <template v-if="index == 0 || exame.quantidadeDeAmostras <= 1 || listaExames[index - 1].id != exame.id">
                                            <span class="panel-block columns is-multiline" style="border: 1px solid red; background-image: none !important; margin: 2px 0px;" 
                                                v-if="exame.bloqueado" :key="index">
                                                <div class="column is-2">
                                                    <h4 class="is-size-5 has-text-weight-bold">
                                                        {{ exame.apelido }}
                                                    </h4>
                                                </div>
                                                <div class="column is-6">
                                                    <p>{{ exame.nome }}</p>
                                                    <small class="is-italic" v-if="exame.quantidadeDeAmostras < 2 && 
                                                        exame.quantidadeDeAmostras != listaExames.filter(exameLista => exameLista.id == exame.id).length">
                                                        {{ exame.materialNome }} / {{ exame.conservanteNome }}
                                                    </small>
                                                    <small v-else>
                                                        <span v-for="(item, i) in listaExames.filter(exameLista => exameLista.id == exame.id)" :key="i">
                                                            {{ item.materialNome }} / {{ item.conservanteNome }}
                                                        </span>
                                                    </small>
                                                    <p v-if="exame.bloqueado">
                                                        <br />
                                                        <span class="tag">{{$t('GUIAATENDIMENTO.BLOQUEADO')}}{{exame.motivoBloqueio}}</span>
                                                    </p>
                                                </div>
                                                <div class="column is-3">
                                                    <p v-if="isExterno" class="is-italic">{{ exame.departamentoNome }}</p>
                                                    <p v-else class="is-italic">{{ exame.setorNome }}</p>
                                                </div>
                                                <div class="column is-1">
                                                    <span class="tag is-pulled-right" @click="mostraDetalhes(exame.id, $event)">
                                                        {{exame.codigo}}
                                                        <b-icon icon="format-list-bulleted"></b-icon>
                                                    </span>
                                                </div>
                                                <div class="column is-12" v-show="mostraDetalhesExameId == exame.id" :key="index">
                                                    <pre style="white-space: pre-wrap;       /* Since CSS 2.1 */
                                                        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                                                        white-space: -pre-wrap;      /* Opera 4-6 */
                                                        white-space: -o-pre-wrap;    /* Opera 7 */
                                                        word-wrap: break-word;  ">Jejum: {{exame.minimoJejum}}
        Prazo em Dias: {{ exame.prazoDeEntrega }} dias, {{ exame.prazoDeEntregaHoras }} horas
        Informações ao Paciente: {{exame.informacoesPaciente}}
        Informações ao Laboratório: {{exame.informacoesLaboratorio}}</pre>
                                                </div>
                                            </span>
                                            <a class="panel-block columns is-multiline" v-else :key="index" @click="incluirExame(exame)" v-bind:class="{ 'has-background-success': incluindoExameIndex == index }">
                                                <div class="column is-2">
                                                    <h4 class="is-size-5 has-text-weight-bold">
                                                        {{ exame.apelido }}
                                                    </h4>
                                                </div>
                                                <div class="column is-6">
                                                    <p>{{ exame.nome }}</p>
                                                    <small class="is-italic" v-if="exame.quantidadeDeAmostras < 2 && 
                                                        exame.quantidadeDeAmostras != listaExames.filter(exameLista => exameLista.id == exame.id).length">
                                                        {{ exame.materialNome }} / {{ exame.conservanteNome }}
                                                    </small>
                                                    <small v-else>
                                                        <span v-for="(item, i) in listaExames.filter(exameLista => exameLista.id == exame.id)" :key="i">
                                                            {{ item.materialNome }} / {{ item.conservanteNome }}
                                                        </span>
                                                    </small>
                                                </div>
                                                <div class="column is-3">
                                                    <p v-if="isExterno" class="is-italic">{{ exame.departamentoNome }}</p>
                                                    <p v-else class="is-italic">{{ exame.setorNome }}</p>
                                                </div>
                                                <div class="column is-1">
                                                    <span class="tag is-pulled-right" @click="mostraDetalhes(exame.id, $event)">
                                                        {{exame.codigo}}
                                                        <b-icon icon="format-list-bulleted"></b-icon>
                                                    </span>
                                                </div>
                                                <div class="column is-12" v-show="mostraDetalhesExameId == exame.id" :key="index">
                                                    <pre style="white-space: pre-wrap;       /* Since CSS 2.1 */
                                                        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                                                        white-space: -pre-wrap;      /* Opera 4-6 */
                                                        white-space: -o-pre-wrap;    /* Opera 7 */
                                                        word-wrap: break-word;  ">Jejum: {{exame.minimoJejum}}
        Prazo em Dias: {{ exame.prazoDeEntrega }} dias, {{ exame.prazoDeEntregaHoras }} horas
        Informações ao Paciente: {{exame.informacoesPaciente}}
        Informações ao Laboratório: {{exame.informacoesLaboratorio}}</pre>
                                                </div>
                                            </a>
                                        </template>
                                    </template>
                                </nav>
                            </div>
                            <div v-show="grupoExames" class="column">
                                <p class="panel-heading" v-if="!showIncluirExame">
                                    {{$t('GUIAATENDIMENTO.INCLUIRGRUPO')}}
                                    <button type="button" class="delete is-pulled-right" aria-label="delete" @click="resetaExibicao"></button>
                                </p>
                                <div class="mb-1">
                                    <b-input 
                                        v-model="procurarGrupoTexto"
                                        @input="inputListaGrupo"
                                        @keydown.native.esc="resetaExibicao"
                                        type="text" 
                                        placeholder="Pesquisar Grupo">
                                    </b-input>
                                </div>
                                <nav class="panel" v-for="(grupo, index) in grupoExames" :key="index">
                                    <p class="panel-heading" @click="incluirGrupoExames(grupo)">
                                        {{ grupo.nome }}
                                    </p>
                                    <a class="panel-block" @click="incluirGrupoExames(grupo)">
                                        <p> 
                                            <template v-for="item in grupo.itens">
                                                <b-tag v-if="item.bloqueado" type="is-danger" :key="item.exameId">
                                                <b-tooltip :label="$t('GUIAATENDIMENTO.ITEMBLOQUEADO')" placement="bottom" position="is-bottom" type="is-light">
                                                    {{ item.apelido }}
                                                </b-tooltip>
                                                </b-tag>
                                                
                                                
                                                <b-tag v-else type="is-info" :key="item.exameId">
                                                    {{ item.apelido }}
                                                </b-tag>
                                            
                                            </template>
                                        
                                        </p>
                                    </a>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div v-show="exameSelecionado.length > 0" class="buttons has-addons is-centered">

                            <b-tooltip :label="$t('GUIAATENDIMENTO.DATASOLICITACAO')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button @click="abrirAlterarDataDeSolicitacao"
                                      v-show="config.permiteAlterarDataExameGuia"
                                      icon-left="calendar"></b-button>
                            </b-tooltip>

                            <b-tooltip :label="$t('GUIAATENDIMENTO.EXAMEREPETIDO')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button @click="confirmarRepeticaoExame"
                                      v-if="isInRole('guia-repetido') && exameSelecionado.every(x => !x.bloqueado && !x.cancelado && !x.assinado)"
                                      icon-left="refresh"></b-button>
                            </b-tooltip>
                  
                            <b-tooltip :label="$t('GUIAATENDIMENTO.DESVINCULAREXAME')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button @click="desvincularExame"
                                      v-if="!guia.guiaConferida && 
                                      (exameSelecionado.every(x => !x.bloqueado && !x.cancelado && !x.digitado && !x.faturado && x.copiaDe))"
                                      icon-left="link-off" type="has-text-danger"></b-button>  
                            </b-tooltip>                                    
                            <b-tooltip :label="$t('GUIAATENDIMENTO.CANCELADIGITACAOEXAMES')" placement="bottom" position="is-bottom" type="is-dark">
                                <b-button 
                                    @click="abrirModalCancelamentoDigitacaoExame" 
                                    v-if="isInRole('cancelar-digitacao-exame') && examesQuePodemCancelarDigitacao" 
                                    icon-left="keyboard-off-outline" type="has-text-danger">

                                </b-button>
                            </b-tooltip>
                            <b-tooltip :label="$t('GUIAATENDIMENTO.CANCELAREXAMES')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button @click="abrirCancelamentoExame"
                                      v-if="isInRole('guia-cancelar') && !guia.guiaConferida && 
                                      (exameSelecionado.every(x => !x.bloqueado && !x.cancelado && !x.digitado && !x.faturado))"
                                      icon-left="close-circle" type="has-text-danger"></b-button>
                            </b-tooltip>

                            <b-tooltip :label="$t('GUIAATENDIMENTO.BLOQUEAREXAMES')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button @click="confirmarBloqueioExame"
                                      v-if="isInRole('guia-bloquear') && exameSelecionado.every(x => !x.bloqueado && !x.cancelado && !x.assinado)"
                                      icon-left="cancel" type="is-black"></b-button>
                            </b-tooltip>

                            <b-tooltip :label="$t('GUIAATENDIMENTO.DESBLOQUEAREXAMES')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button @click="confirmarDesbloqueioExame"
                                      v-show="isInRole('guia-desbloquear') && exameSelecionado.every(x => x.bloqueado && !x.cancelado)"
                                      icon-left="checkbox-marked-circle-outline" type="has-text-success"></b-button>
                            </b-tooltip>

                            <b-tooltip :label="$t('GUIAATENDIMENTO.REPORTAREXAMES')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button @click="confirmarAlertaExame"
                                      v-show="isInRole('guia-reportaraomedico')"
                                      icon-left="bullhorn"></b-button>
                            </b-tooltip>

                            <b-tooltip :label="$t('GUIAATENDIMENTO.RECEBERMATERIAL')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button tag="router-link"
                                      :to="{ name: 'coleta', query: { guiaId: guia.id, item: exameSelecionado.map(x => x.item) } }"
                                      v-if="isInRole('guia-receber-amostra') && exameSelecionado.every(x => !x.bloqueado && !x.cancelado && !x.materialEntregue && !x.exameDeFaturamento && !x.exameSetorDeImagem && !x.exameDeOutrasDespesas && !x.exameDePerfil)"
                                      icon-left="water" type="has-text-success"></b-button>
                            </b-tooltip>

                            <b-tooltip :label="$t('GUIAATENDIMENTO.ENTREGARESULTADOS')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button @click="confirmarEntregaDeResultados"
                                      v-show="isInRole('resultado-entrega') && exameSelecionado.every(x => !x.bloqueado && !x.cancelado && x.assinado)"
                                      icon-left="send"></b-button>
                            </b-tooltip>

                            <b-tooltip :label="$t('GUIAATENDIMENTO.INCLUIROUTRASDESPESAS')" placement="left" position="is-left" type="is-dark">
                            <b-button v-show="exameSelecionado.length == 1 && exameSelecionado.every(x => !x.faturado)"
                                      @click="incluirExameOutraDespesas(exameSelecionado)"
                                      icon-left="plus-box " ></b-button>
                            </b-tooltip>

                            <b-tooltip :label="$t('GUIAATENDIMENTO.UPLOADIMAGEM')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button @click="anexarEstudo"
                                      v-show="exameSelecionado.every(x => x.exameSetorDeImagem) && exameSelecionado.length == 1"
                                      icon-left="upload"></b-button>
                            </b-tooltip>

                            <b-tooltip :label="$t('GUIAATENDIMENTO.DATACOLETA')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button @click="abrirAlterarDataDeColeta"
                                      v-show="isInRole('guia-alterar-datacoleta') && exameSelecionado.every(x => x.materialEntregue == true)"
                                      icon-left="pencil"></b-button>
                            </b-tooltip>
                            <b-tooltip :label="$t('GUIAATENDIMENTO.ALTERARCODIGODEINTEGRACAO')" placement="bottom" position="is-bottom" type="is-dark">
                                <b-button 
                                    @click="alterarCodigoIntegracaoExame"
                                    v-show="isInRole('guia-alterar-dadosIntegracao') && exameSelecionado.length === 1"
                                    icon-left="pencil-outline">
                                </b-button>
                            </b-tooltip>
                            <b-tooltip :label="$t('GUIAATENDIMENTO.INFORMACOES')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button @click="abrirInfoExame"
                                      icon-left="information" type="has-text-info"></b-button>
                            </b-tooltip>
                        </div>
                        <div v-show="amostraSelecionada.length > 0" class="buttons has-addons is-centered">
                            
                            <b-tooltip :label="$t('GUIAATENDIMENTO.CANCELARAMOSTRAS')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button v-show="isInRole('guia-cancelar') && !guia.guiaConferida && amostraSelecionada.every(x => !x.cancelada)"
                                      @click="abrirCancelamentoAmostra"
                                      icon-left="close-circle" type="has-text-danger"></b-button>
                            </b-tooltip>

                            <b-tooltip :label="$t('GUIAATENDIMENTO.CANCELARCOLETA')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button v-show="isInRole('amostra-coleta-cancelar') && !guia.guiaConferida 
                                      && amostraSelecionada.every(x => !x.cancelada) && verificaAmostrasColeta() 
                                      && amostraSelecionada.length == 1"
                                      @click="abrirCancelamentoColetaAmostra "
                                      icon-left="water-off" type="has-text-danger"></b-button>
                            </b-tooltip>

                            <b-tooltip :label="$t('GUIAATENDIMENTO.RECEBERAMOSTRA')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button tag="router-link"
                                      v-show="isInRole('guia-receber-amostra') && amostraSelecionada.every(x => !x.cancelada)"
                                      :to="{ name: 'recebimentoamostras', query: { codigoDeBarras: amostraSelecionada.length > 0 ? amostraSelecionada[0].codigoDeBarras : null } }"
                                      icon-left="water" type="has-text-success"></b-button>
                            </b-tooltip>

                            <b-tooltip :label="$t('GUIAATENDIMENTO.ARMAZENARSOROTECA')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button tag="router-link"
                                      :to="{ name: 'soroteca-armazenar', query: { amostraSelecionada: amostraSelecionada.length > 0 ? this.amostraSelecionada.map(x => x.codigoDeBarras).join(',') : null } }"
                                      icon-left="archive"></b-button>
                            </b-tooltip>

                            <b-tooltip :label="$t('GUIAATENDIMENTO.IMPRIMIRETIQUETA')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button @click="imprimirEtiquetaAmostraSelecionada"
                                      icon-left="barcode"></b-button>
                            </b-tooltip>

                            <b-tooltip :label="$t('GUIAATENDIMENTO.ALTERARCODIGO')" placement="left" position="is-left" type="is-dark">
                            <b-button v-show="isInRole('guia-alterar-codigo-barras') && amostraSelecionada.length == 1"
                                      @click="confirmarAlterarCodigoDeBarras"
                                      icon-left="eraser"></b-button>
                            </b-tooltip>

                            <b-tooltip :label="$t('GUIAATENDIMENTO.REGISTRARINCIDENCIA')" placement="left" position="is-left" type="is-dark">
                            <b-button v-show="isInRole('guia-incidenciaAmostra')"
                                      @click="abrirIncidenciaAmostra"
                                      icon-left="alert-rhombus"
                                      type="has-text-warning"></b-button>
                            </b-tooltip>

                            <b-tooltip :label="$t('GUIAATENDIMENTO.ENVIARAMOSTRA')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button v-show="isInRole('menu-integracao')"
                                      @click="enviarAmostraIntegracao"
                                      icon-left="upload"></b-button>
                            </b-tooltip>

                            <!--<b-button title="Solicitar resultado para integração"
                                      v-show="isInRole('menu-integracao') && amostraSelecionada.every(x => !x.cancelada && x.integradorId != null)"
                                      icon-left="download"></b-button>-->

                            <b-tooltip :label="$t('GUIAATENDIMENTO.REIMPRIMIRINTEGRACAO')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button v-show="isInRole('menu-integracao') && amostraSelecionada.every(x => x.integradorId)"
                                      @click="reimprimirAmostraIntegracao"
                                      icon-left="cloud-print-outline"></b-button>
                            </b-tooltip>

                            <b-tooltip :label="$t('GUIAATENDIMENTO.INFORMACOES')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button icon-left="information"
                                      @click="abrirInfoAmostra"
                                      type="has-text-info"></b-button>
                            </b-tooltip>
                            
                        </div>
                        <div v-show="estudoSelecionado.length > 0" class="buttons has-addons is-centered">
<!--                             TODO
                            <b-button title="Download do arquivo DICOM"
                                      v-show="isInRole('menu-integracao')"
                                      @click="receberEstudoIntegracao"
                                      icon-left="download"></b-button>

                            <b-button title="Enviar estudo para integração"
                                      v-show="isInRole('menu-integracao')"
                                      @click="enviarEstudoIntegracao"
                                      icon-left="upload"></b-button> -->

                            <b-tooltip :label="$t('GUIAATENDIMENTO.INFORMACOES')" placement="bottom" position="is-bottom" type="is-dark">
                            <b-button icon-left="information"
                                      @click="abrirInfoEstudo"
                                      type="has-text-info"></b-button>
                            </b-tooltip>
                        </div>
                        <div class="panel" style="padding: 0.3rem;">
                            <b-button v-show="amostraSelecionada.length == 1"
                                      expanded native-class="panel-block" type="is-primary" @click="abrirSolicitarNovaAmostra" style="margin-bottom: 5px; width: 95% ">
                                {{$t('GUIAATENDIMENTO.SOLICITARNOVAAMOSTRA')}}
                            </b-button>

                            <b-button v-show="!isIncluindoExame && 
                                isInRole('guia-incluir-exame') && 
                                !guia.guiaConferida && 
                                (exames.length == 0 || (!exames.every(x => x.cancelado || x.faturado)))"
                                      icon-left="plus"
                                      expanded native-class="panel-block" type="is-success" @click="mostrarIncluindoExame" style="margin-bottom: 5px; width: 95% ">
                                {{$t('GUIAATENDIMENTO.INCLUIR')}}
                            </b-button>

                            <b-button tag="a" 
                                      v-show="exames.some(x => !x.bloqueado && !x.cancelado && x.materialEntregue && !x.digitado && !x.assinado)"
                                      @click="digitarResultadosPor()"
                                      expanded
                                      type="is-success"
                                      icon-left="keyboard-variant" style="margin-bottom: 5px; width: 95%">
                                {{$t('GUIAATENDIMENTO.DIGITAR')}}
                            </b-button>
                            <b-dropdown expanded aria-role="list" scrollable>
                                <button class="button is-info is-fullwidth" slot="trigger" slot-scope="{ active }" style="margin-bottom: 5px; width: 95%">
                                    <span>{{$t('GUIAATENDIMENTO.COMPROVANTE')}}</span>
                                    <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                                </button>
                                <b-dropdown-item aria-role="listitem" v-for="(comprovante, index) in modelosComprovante" :key="index">
                                    <span v-if="comprovante.variaveis.length == 0" class="column full-width is-flex is-justify-content-space-between" >
                                        <span v-if="comprovante.rawPrinting" >                                                                                
                                            <a :class="config.enviaDocAssinatura ? 'column is-7' : 'column is-9'" style="color: #4a4a4a; text-decoration: none;" @click="comprovanteGuia(guia.id, comprovante.nome)">
                                                {{comprovante.nome}}
                                            </a>
                                        </span>
                                        <span v-else class="columns">
                                            <span :class="config.enviaDocAssinatura ? 'column is-7' : 'column is-9'">{{comprovante.nome}}</span>
                                            <a @click="comprovanteGuia(guia.id, comprovante.nome)">
                                                <b-icon :icon="'eye'"></b-icon>
                                            </a>
                                            <a  @click="imprimeComprovanteGuia(guia.id, comprovante.nome)">
                                                <b-icon :icon="'printer'"></b-icon>
                                            </a>
                                            <a @click="envioComprovanteEmail(guia.id, comprovante.nome, guia)">
                                                <b-icon icon="email"></b-icon>
                                            </a>
                                            <a v-if="config.enviaDocAssinatura"  @click="enviaDocumento(guia.id, comprovante.nome, guia)">
                                                <b-icon icon="pen"></b-icon>
                                            </a>
                                        </span>                        
                                    </span>
                                    <span v-else class="columns pl-1">
                                        <span class="column">                                                                                
                                            <a style="color: #4a4a4a; text-decoration: none;" @click="preencherVariaveis(comprovante)">
                                                {{comprovante.nome}}
                                            </a>

                                        </span>
                                    </span>
                                </b-dropdown-item>
                                <b-dropdown-item  v-if="guia.convenioControleDeGuiasSadt"  >
                                    <hr  class="dropdown-divider">
                                    <span>
                                        <span class="columns">                                                                                
                                            <a target="_blank" class="column is-12" :href="'/Guia/TissGuiaSadt?id=' + guia.id" style="color: #4a4a4a; text-decoration: none;" >
                                                {{$t('GUIAATENDIMENTO.GUIASP')}}
                                            </a>

                                        </span>
                                    </span> 

                                </b-dropdown-item>

                                <b-dropdown-item  v-if="guia.convenioControleDeGuiasSadt"  >
                                    <hr  class="dropdown-divider">
                                    <span>
                                        <span class="columns">                                                                                
                                            <a target="_blank" class="column is-12" :href="'/api/atendimento/TissOutrasDespesasSadt?id=' + guia.id" style="color: #4a4a4a; text-decoration: none;" >
                                                {{$t('GUIAATENDIMENTO.OUTRASDESPESAS')}}
                                            </a>

                                        </span>
                                    </span> 
                                </b-dropdown-item>
                                             

                            </b-dropdown>

                            <b-button v-show="exames.length > 0 && exames.every(x => x.cancelado || x.assinado)"
                                      tag="a" :href="'/Guia/NotificarGuiaCompleta?id=' + guia.id + '&returnUrl=' + $router.resolve({ name: 'guia', params: { id: guia.id } }).href"
                                      expanded native-class="panel-block" type="is-primary " style="margin-bottom: 5px; width: 95%">
                                {{$t('GUIAATENDIMENTO.NOTIFICAR')}}
                            </b-button>

                            <b-button v-show="exames.some(x => !x.bloqueado && !x.cancelado && x.materialEntregue && x.digitado) && isInRole('resultado-assinar')"
                                      tag="a" :href="'/Guia/Assinatura?guiaId=' + guia.id + '&returnUrl=' + $router.resolve({ name: 'guia', params: { id: guia.id } }).href"
                                      icon-left="draw"
                                      type="is-success"
                                      expanded native-class="panel-block" style="margin-bottom: 5px; width: 95%">
                                {{$t('GUIAATENDIMENTO.ASSINAR')}}
                            </b-button>

                            <b-button v-show="amostras"
                                      @click="imprimirTodasEtiquetas"
                                      icon-left="label-outline"
                                      expanded native-class="panel-block" type="is-primary" style="margin-bottom: 5px; width: 95%">
                                {{$t('GUIAATENDIMENTO.ETIQUETAS')}}
                            </b-button>

                            <template v-for="(resultado, index) in modelosResultado">
                                <b-button :key="index"
                                          v-if="resultado.nome == 'Pré-Visualizar Laudo'"
                                          v-show="(isInRole('resultado-previsualizacao') && exames.some(x => x.digitado))"
                                          @click="preVisualizarPdf(null)"
                                          type="is-primary"
                                          expanded native-class="panel-block" style="margin-bottom: 5px; width: 95%">
                                    {{resultado.nome}}
                                </b-button>
                            </template>

                            <b-dropdown expanded rounded aria-role="list" scrollable
                                v-show="((isInRole('resultado-visualizar') || isInRole('resultado-email') || isInRole('resultado-imprimir')) && exames.some(x => !x.laudoRestrito) && 
                                        (((!guia.temPendencia && guia.paga) || isInRole('acessar-resultado-pendente-pagamento')) || !guia.convenioTipoParticular)) || 
                                        isInRole('laudo-restrito') && exames.some(x => x.assinado || (x.exameResultadoParcial && x.digitado))">

                                <button class="button is-fullwidth is-info" slot="trigger" slot-scope="{ active }" style="margin-bottom: 5px; width: 95%">
                                    <span>{{$t('GUIAATENDIMENTO.RESULTADOS')}}</span>
                                    <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                                </button>

                                <b-dropdown-item aria-role="listitem" v-show="(isInRole('resultado-visualizar') && exames.some(x => !x.laudoRestrito) ) || isInRole('laudo-restrito')">
                                    <span class="columns">
                                        <a class="column is-12" style="color: #4a4a4a; text-decoration: none;" @click="visualizarPdf(null)">
                                            {{$t('GUIAATENDIMENTO.VISUALIZAR')}}
                                        </a>
                                    </span>
                                </b-dropdown-item>

                                <b-dropdown-item aria-role="listitem" v-show="isInRole('resultado-imprimir')">
                                    <span class="columns">
                                        <a class="column is-12" style="color: #4a4a4a; text-decoration: none;" @click="imprimirPdf()">
                                            {{$t('GUIAATENDIMENTO.IMPRIMIR')}}
                                        </a>
                                    </span>
                                </b-dropdown-item>

                                <template v-for="(resultado, index) in modelosResultado">
                                    <b-dropdown-item v-show="(isInRole('resultado-visualizar') && exames.some(x => !x.laudoRestrito) ) || isInRole('laudo-restrito')" aria-role="listitem" v-if="resultado.nome != 'Pré-Visualizar Laudo'" :key="index">
                                        <span class="columns">
                                            <a class="column is-12" style="color: #4a4a4a; text-decoration: none;" @click="visualizarPdf(resultado.nome)">
                                                {{resultado.nome}}
                                            </a>
                                        </span>
                                    </b-dropdown-item>
                                </template>

                                <b-dropdown-item aria-role="listitem" v-show="isInRole('resultado-email')">
                                    <span class="columns">
                                        <a class="column is-12" style="color: #4a4a4a; text-decoration: none;" @click="emailMedico()">
                                            {{$t('GUIAATENDIMENTO.EMAILMEDICO')}}
                                        </a>
                                    </span>
                                </b-dropdown-item>

                                <b-dropdown-item aria-role="listitem" v-show="isInRole('resultado-email')">
                                    <span class="columns">
                                        <a class="column is-12" style="color: #4a4a4a; text-decoration: none;" @click="emailPaciente()">
                                            {{$t('GUIAATENDIMENTO.EMAILPACIENTE')}}
                                        </a>
                                    </span>
                                </b-dropdown-item>

                            </b-dropdown>

                            <b-button v-show="(isInRole('receber-alterar') || isInRole('receber-alterar-recepcao')) && guia.convenioTipoParticular"
                                      @click="redirecionarPagamentoGuia"
                                      icon-left="cash"
                                      expanded native-class="panel-block" type="is-primary" style="margin-bottom: 5px; width: 95%">
                                {{$t('GUIAATENDIMENTO.PAGAMENTO')}}
                            </b-button>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <b-tabs @input="mudouObsTab" v-model="tabs" multiline>
                        <b-tab-item value="observacoes" :label="$t('GUIAATENDIMENTO.OBSERVACOES')">
                            <textarea class="textarea" v-model="guia.observacoes" rows="4"></textarea>
                        </b-tab-item>
                        <b-tab-item value="observacoesDoPortal" :label="$t('GUIAATENDIMENTO.OBSERVACOESDOPORTAL')">
                            <textarea class="textarea" v-model="guia.observacoesDoPortal" rows="4"></textarea>
                        </b-tab-item>
                        <b-tab-item  value="entrega"  :label="$t('GUIAATENDIMENTO.ENTREGA')">
                            <searchIdName :id.sync="guia.localDeEntregaId" label="Local de entrega" table="Local"></searchIdName>
                        </b-tab-item>
                        <b-tab-item  value="hipotese" :label="$t('GUIAATENDIMENTO.HIPOTESE')">
                            <div class="columns">
                                <div class="column is-one-fifth">
                                    <b-field :label="$t('GUIAATENDIMENTO.CID10')" :type="cid10type" :message="cid10message">
                                        <b-input v-model="guia.cid10"
                                                 @blur="getCid10"
                                                 v-mask="'A###'"
                                                 :loading="cid10loading"
                                                 icon="magnify"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('GUIAATENDIMENTO.DESCRICAO')">
                                        <b-input v-model="cid10descricao" readonly></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('GUIAATENDIMENTO.INDICACAO')">
                                        <b-input v-model="guia.indicacaoClinica"></b-input>
                                    </b-field>
                                </div>
                            </div>
                        </b-tab-item>
                        <b-tab-item value="medicamentos" :label="$t('GUIAATENDIMENTO.MEDICAMENTOS')">
                            <b-taginput :data="filteredMedicamentos"
                                        autocomplete
                                        allow-new
                                        v-model="guia.medicamentos"
                                        icon="pill"
                                        placeholder="Inclua um medicamento"
                                        @typing="getMedicamentos">
                            </b-taginput>
                        </b-tab-item>
                        <b-tab-item value="autorizacao" :label="$t('GUIAATENDIMENTO.AUTORIZACAO')">
                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('GUIAATENDIMENTO.GUIAOPERADORA')">
                                        <b-input v-model="guia.autorizacaoGuia"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('GUIAATENDIMENTO.SENHA')">
                                        <b-input v-model="guia.autorizacaoSenha"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('GUIAATENDIMENTO.AUTORIZADOEM')">
                                        <b-datepicker editable v-model="guia.autorizacao" position="is-top-right"></b-datepicker>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('GUIAATENDIMENTO.VALIDADE')">
                                        <b-datepicker editable v-model="guia.autorizacaoSenhaValidade" position="is-top-left"></b-datepicker>
                                    </b-field>
                                </div>
                                <div class="column" style="display: flex; align-items: end;">
                                    <b-field grouped>
                                        <p class="control">
                                            <b-button type="is-danger" @click="tissCancelarGuia(guia.id)">
                                                {{$t('GUIAATENDIMENTO.CANCELAR')}}
                                            </b-button>
                                        </p>
                                        
                                        <p class="control">
                                            <b-button type="is-warning" @click="tissConsultarGuia(guia.id)">
                                                {{$t('GUIAATENDIMENTO.CONSULTAR')}}
                                            </b-button>
                                        </p>
                                    </b-field>
                                </div>
                            </div>
                        </b-tab-item>
                        <b-tab-item v-if="guia != null && guia.convenioTipoParticular && agendamentoDashboard != 'true'"
                                    value="desconto"
                                    label="Desconto">
                            <b-message v-if="descontoMensagem" type="is-danger" :aria-close-label="$t('GUIAATENDIMENTO.FECHARMENSAGEM')">
                                {{ descontoMensagem }}
                            </b-message>
                            <div class="columns">
                                <div class="column is-4">
                                    <b-field :label="$t('GUIAATENDIMENTO.DESCONTOACORDADO')">
                                        <b-input type="text" v-model="guia.desconto" ref="inputDescontoAcordado" :readonly="this.exames.some(x => x.faturado)" @input="formataDesconto" oninput="this.value = this.value.replace(/[^0-9%.,-]/g, '').replace(/%.*%/g, '%').replace(/\..*\./g, '.');"/>
                                    </b-field>
                                    <b-field :label="$t('GUIAATENDIMENTO.TOTAL')">
                                        <b-input type="text" :value="totalExames| currency" readonly></b-input>
                                    </b-field> 
                                    <b-field :label="$t('GUIAATENDIMENTO.COMDESCONTO')">
                                        <b-input type="text" :value="totalExamesDesconto | currency" readonly></b-input>
                                    </b-field>
                                    <b-field :label="$t('GUIAATENDIMENTO.CONDICAO')" v-show="this.exames.some(x => !x.faturado)">
                                        <b-select v-model="guia.condicaoPagamento">
                                            <option v-for="(condpag, index) in listaCondicoesPagamento" :key="index"
                                                    :value="condpag.id">
                                                {{ condpag.descricao }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-table :data="exames == null ? [] : exames.filter(config.naoFaturarExamesVinculados ? x => !x.cancelado && !x.vinculado : x => !x.cancelado)" bordered narrowed striped>
                                        <template >
                                            <b-table-column  v-slot="props" field="item" label="Item">
                                                {{ props.row.item }}
                                            </b-table-column>
                                            <b-table-column  v-slot="props" field="exameApelido" :label="$t('GUIAATENDIMENTO.EXAME')">
                                                <b-tooltip :label="$t('GUIAATENDIMENTO.EXAMEFATURADO')" placement="bottom" position="is-bottom" type="is-light">
                                                <b-tag type="is-success" v-if="props.row.faturado">{{ props.row.exameApelido }}</b-tag>
                                                <span v-else>{{ props.row.exameApelido }}</span>
                                            </b-tooltip>
                                            </b-table-column>
                                            <b-table-column  v-slot="props" field="preco" :label="$t('GUIAATENDIMENTO.PRECO')" class="has-text-right">
                                                {{ props.row.preco | currency }}
                                            </b-table-column>
                                            <b-table-column  v-slot="props" field="desconto" :label="$t('GUIAATENDIMENTO.DESCONTOEXAME')" class="has-text-right">
                                                <b-input v-if="!props.row.faturado && !guia.desconto" v-model="props.row.exameDesconto"  size="is-small" type="text" oninput="this.value = this.value.replace(/[^0-9%.,-]/g, '').replace(/%.*%/g, '%').replace(/\..*\./g, '.');" @input="totalDescontoPorExame(props.row.exameDesconto, props.row)"></b-input>
                                                <span v-else>{{props.row.exameDesconto | currency}}</span>
                                            </b-table-column>
                                            <b-table-column  v-slot="props" field="total" :label="$t('GUIAATENDIMENTO.TOTAL')" class="has-text-right">
                                                {{ (props.row.precoTotalExame ?? 0) | currency }}
                                            </b-table-column>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                        </b-tab-item>
                        <b-tab-item v-if="guia.convenioControleDeGuiasSadt"
                                    value="tabSadt"
                                    :label="$t('GUIAATENDIMENTO.GUIASADT')">
                            <div class="columns is-multiline">
                                <div class="column is-6 card" v-for="(sadt,index) in sadts" :key="index">
                                    <div class="columns">
                                        <div class="column is-3">
                                            <b-field :label="$t('GUIAATENDIMENTO.NUMERO')" label-position="on-border">
                                                <b-input size="is-small" v-model="sadt.numero"></b-input>
                                            </b-field>
                                        </div>
                                        <div class="column is-3">
                                            <b-field :label="$t('GUIAATENDIMENTO.SENHA')" label-position="on-border">
                                                <b-input size="is-small" v-model="sadt.autorizacaoSenha"></b-input>
                                            </b-field>
                                        </div>
                                        <div class="column is-3">
                                            <b-field :label="$t('GUIAATENDIMENTO.AUTORIZADOEM')" label-position="on-border">
                                                <b-datepicker position="is-top-right" editable icon="calendar-today" size="is-small" v-model="sadt.autorizacao"></b-datepicker>
                                            </b-field>
                                        </div>
                                        <div class="column is-3">
                                            <b-field :label="$t('GUIAATENDIMENTO.VALIDADE')" label-position="on-border">
                                                <b-datepicker  position="is-top-right" editable icon="calendar-today" size="is-small" v-model="sadt.autorizacaoSenhaValidade"></b-datepicker>
                                            </b-field>
                                        </div>
                                    </div>

                                    <draggable :list="sadt.exames" group="guiaSadt" @change="mudouSadt($event,sadt.id)">
                                        <b-tag type="is-primary sadtExame" v-for="exame in sadt.exames" :key="exame.item">
                                            {{ exame.apelido }}
                                        </b-tag>
                                    </draggable>
                                </div>
                                <div class="column is-3">
                                    <b-button expanded @click="incluirSadt">
                                        {{$t('GUIAATENDIMENTO.INCLUIRGUIASADT')}}
                                    </b-button>
                                </div>
                            </div>
                        </b-tab-item>
                        <b-tab-item v-if="guia.id !== 0 && isInRole('guia-altera-resposta-pergunta') && exames.some(x => x.pesquisaClinicaRespondida ||x.pesquisaClinicaRespondidaColeta || x.statusExame === 2)"
                                    :label="$t('GUIAATENDIMENTO.RESPOSTAS')"
                                    icon="help-circle"
                                    value="respostas">

                            <p v-if="respostasPesquisas != null && respostasPesquisas.length == 0">
                                {{$t('GUIAATENDIMENTO.NAOEXISTERESPOSTAS')}}
                            </p>

                            <b-loading :active.sync="isLoadingRespostas"></b-loading>
                            <respostasPesquisasVue :respostasPesquisas="respostasPesquisas" :exames="exames"></respostasPesquisasVue>                            

                        </b-tab-item>
                        <b-tab-item  label="Histórico" value="historicoAlteracoes" icon="history">
                            <p v-if="historicoAlteracoes != null && historicoAlteracoes.length == 0">
                                {{$t('GUIAATENDIMENTO.NENHUMAINFORMACAO')}}
                            </p>

                            <table class="table is-fullwidth">
                                <tr v-for="(item, index) in historicoAlteracoes" :key="index">
                                    <td>
                                        <h4>
                                            {{ item.descricao }}
                                            <b-tooltip :label="$t('GUIAATENDIMENTO.EDITAR')" placement="bottom" position="is-bottom" type="is-light">
                                                <router-link :to="{ name: 'cadastro-fatura', params: { id: item.valorInformado }}" 
                                                    v-if="item.acao == 203" class="button is-small is-primary">
                                                    <b-icon icon="pencil" size="is-small"></b-icon>
                                                </router-link>
                                            </b-tooltip>
                                        </h4>
                                    </td>
                                    <td>
                                        <span><b>{{ item.funcionarioNome ? item.funcionarioNome : item.usuarioNome }}</b> ({{ item.usuarioId }})</span>
                                        
                                    </td>
                                    <td>
                                        <span>{{ item.dataHora | moment('DD/MM/YYYY HH:mm') }}</span>
                                    </td>
                                </tr>
                            </table>
                        </b-tab-item>
                        <b-tab-item v-if="guia.Id != 0"
                                    value="vinculos"
                                    :label="$t('GUIAATENDIMENTO.VINCULOS')"
                                    icon="link-variant">
                            <table v-if="guia.guiaPaiId || (guiasFilhas && guiasFilhas.length > 0)"
                                   class="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>{{$t('GUIAATENDIMENTO.GUIA')}}</th>
                                        <th>{{$t('SISTEMA.CONVENIO')}}</th>
                                        <th>{{$t('SISTEMA.SOLICITANTE')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="guia.guiaPaiId">
                                        <td>
                                            <router-link :to="{ name: 'guia', params: { id: guia.guiaPaiId }}" class="button is-link is-small">
                                                {{ guia.guiaPaiId }}
                                            </router-link>
                                        </td>
                                        <td>
                                            {{ guia.guiaPaiConvenioNome }}
                                        </td>
                                        <td>
                                            {{ guia.guiaPaiMedicoNome }}
                                        </td>
                                    </tr>
                                    <tr v-for="(filha, index) in guiasFilhas" :key="index">
                                        <td>
                                            <router-link :to="{ name: 'guia', params: { id: filha.id }}" class="button is-link is-small">
                                                {{ filha.id }}
                                            </router-link>
                                        </td>
                                        <td>
                                            {{ filha.convenioNome }}
                                        </td>
                                        <td>
                                            {{ filha.medicoNome }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-else>
                                <div class="column is-6">
                                    <b-field :label="$t('GUIAATENDIMENTO.VINCULARGUIA')" label-position="on-border">
                                        <b-input type="number" v-model="vincularGuiaId"></b-input>
                                        <p class="control">
                                            <button class="button is-success" @click="vincularGuia">{{$t('GUIAATENDIMENTO.VINCULAR')}}</button>
                                        </p>
                                    </b-field>
                                </div>
                            </div>
                        </b-tab-item>
                        <b-tab-item v-if="this.guia.id != 0" value="anexos"
                                    :label="$t('GUIAATENDIMENTO.ANEXOS')" icon="paperclip">
                            <div class="columns">
                                <div class="column" v-if="isInRole('guia-incluir-anexo')">
                                    <b-field>
                                        <b-upload 
                                        :key="uploadKey"
                                                  @input="incluirAnexo"
                                                  multiple
                                                  accept=".jpg,.jpeg,.png,.bmp,.pdf"
                                                  drag-drop>
                                            <section class="section">
                                                <div class="content has-text-centered">
                                                    <p>
                                                        <b-icon icon="upload"
                                                                size="is-medium">
                                                        </b-icon>
                                                    </p>
                                                    <p>{{$t('ANEXOS.ARRASTEARQUIVOS')}}</p>
                                                    <p>({{$t('ANEXOS.APENASPDF')}})</p>
                                                </div>
                                            </section>
                                        </b-upload>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <table class="table is-striped">
                                        <tbody>
                                            <tr v-for="(anexo, index) in anexos" :key="index">
                                                <td width="90%">
                                                    <p v-if="anexo.name">
                                                        <span>{{ anexo.name }}</span><br>
                                                        <span class="is-size-7 text-nowrap"><b>{{ anexo.fileName }}</b></span>
                                                    </p>
                                                    <p v-else>
                                                        <span v-if="anexo.anexo">{{ anexo.anexo.replace(/^.*[\\\/]/, '') }}</span>
                                                        <span v-else>{{$t('GUIAATENDIMENTO.ANEXO')}} {{ index + 1 }}</span><br>
                                                        <a @click="visualizarAnexo(anexo.id, anexo.fileName)" class="is-size-7 text-nowrap"><b>{{ anexo.fileName }}</b></a>
                                                    </p>
                                                </td>
                                                <td>
                                                    <span>{{guiaExameInfo(anexo.item)?.exameApelido}}</span>
                                                </td>
                                                <td v-if="anexo.id">
                                                        <b-tooltip v-if="anexo.fileName.includes('.pdf')" position="is-left" label="Exibir no laudo">
                                                            <b-switch size="is-small" v-model="anexo.exibirNoLaudo"  @input="exibeNoLaudo(anexo)"></b-switch>
                                                        </b-tooltip> 

                                                    <b-tooltip :label="$t('ANEXOS.REMOVERANEXO')" placement="bottom" position="is-bottom" type="is-light">
                                                        <b-button v-if="isInRole('guia-excluir-anexo')"
                                                                icon-left="delete"
                                                                type="is-danger"
                                                                size="is-small"
                                                                @click="removerAnexo(anexo.id)"></b-button>
                                                        </b-tooltip>
                                                </td>
                                            </tr>
                                            <tr v-if="uploadingAnexo">
                                                <td colspan="2">
                                                    <b-progress type="is-info" :value="uploadingAnexo" show-value></b-progress>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </b-tab-item>
                    </b-tabs>
                </div>
            </div>
            <nav class="level">
                <div class="level-item">
                    <p class="buttons">
                        <b-button type="is-danger"
                                  native-type="button"
                                  icon-left="close-circle"
                                  @click="$router.back();">
                            {{$t('GUIAATENDIMENTO.CANCELAR')}}
                        </b-button>
                        
                        <b-button type="is-success"
                                  :loading="salvandoDados"
                                   @click="salvarGuia"
                                  icon-left="check-circle">
                            {{$t('GUIAATENDIMENTO.SALVAR')}}
                        </b-button>
                    </p>
                </div>
            </nav>
        </ValidationObserver>

        <b-modal :active.sync="isModalComprovante">
            <div class="panel">
                <div class="panel-heading">
                    {{$t('GUIAATENDIMENTO.COMPROVANTES')}}
                </div>
                <div class="panel-block">
                    {{$t('GUIAATENDIMENTO.FICHA')}}
                </div>
                <a class="panel-block">
                    {{$t('GUIAATENDIMENTO.TEXTO')}}
                </a>
                <a class="panel-block">
                    {{$t('GUIAATENDIMENTO.GUIASADT')}}
                </a>
            </div>
        </b-modal>

        <b-modal :active.sync="isModalResultado">
            <div class="panel">
                <div class="panel-heading">
                    {{$t('GUIAATENDIMENTO.RESULTADOS')}}
                </div>
                <a class="panel-block">
                    {{$t('GUIAATENDIMENTO.VISUALIZAR')}}
                </a>
                <a class="panel-block">
                    {{$t('GUIAATENDIMENTO.IMPRIMIR')}}
                </a>
            </div>
        </b-modal>
    </section>
</template>

<style scoped>
    .sadtExame {
        margin-right: 2px;
        cursor: pointer;
    }
</style>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import guiaAmostra from '@/components/atendimento/amostra.vue'
    import guiaExame from '@/components/atendimento/guiaExame.vue'
    import estudo from '@/components/atendimento/estudo.vue'
    import respostasPesquisasVue from '@/components/atendimento/respostasPesquisas.vue'
    import guiaindicadores from '@/components/atendimento/guiaIndicadores.vue'
    import modalInfoExame from '@/components/atendimento/modalInfoExame.vue'
    import modalComprovanteVariavel from '@/components/atendimento/modalComprovanteVariavel.vue'
    import modalInfoAmostra from '@/components/atendimento/modalInfoAmostra.vue'
    import modalInfoEstudo from '@/components/atendimento/modalInfoEstudo.vue'
    import modalSolicitarNovaAmostra from '@/components/atendimento/modalSolicitarNovaAmostra.vue'
    import modalAlterarDataDeSolicitacao from '@/components/atendimento/modalAlterarDataDeSolicitacao.vue'
    import modalAlterarDataDeColeta from '@/components/atendimento/modalAlterarDataDeColeta.vue'
    import modalCalculoTempoJejum from '@/components/atendimento/modalCalculoTempoJejum.vue'
    import modalCancelandoAmostra from '@/components/atendimento/modalCancelandoAmostra'
    import modalCancelandoColetaAmostra from '@/components/atendimento/modalCancelandoColetaAmostra'
    import modalCancelandoExame from '@/components/atendimento/modalCancelandoExame'
    import modalCancelandoDigitacaoExame from '@/components/atendimento/modalCancelandoDigitacaoExame'
    import modalIncidenciaAmostra from '@/components/atendimento/modalIncidenciaAmostra'
    import modalBloqueioExame from '@/components/atendimento/modalBloqueioExame'
    import modalDesbloqueioExame from '@/components/atendimento/modalDesbloqueioExame'
    import modalAlertaExame from '@/components/atendimento/modalAlertaExame'
    import draggable from 'vuedraggable'
    import debounce from 'lodash/debounce'
    import modalAnexoVue from '../../components/atendimento/modalAnexo.vue'
    import modalObservacaoConvenio from '@/components/atendimento/modalObservacaoConvenio'
    import modalAlterarCodigoIntegracaoExame from '@/components/atendimento/modalAlterarCodigoIntegracaoExame'
    import { length } from 'vee-validate/dist/rules'

    export default {
        data() {
            return {
                infoConvenio: false,
                alterandoUnidade: false,
                alterandoCodigoIntegracaoGuia: false,
                convenioAtual: 0,
                guia: {
                    id: null,
                    cid10: null,
                    agendamento: null,
                    tipoAgendamentoNome: null,
                    unidadeDeCadastroId: null,
                    integradorId: null,
                    localDeOrigemNome: null,
                },
                exames: [],
                amostras: [],
                estudos: [],
                sadts: [],
                respostasPesquisas: [],
                historicoAlteracoes: null,
                guiasFilhas: null,
                anexos: null,

                salvandoDados: false,
                isModalComprovante: false,
                isModalResultado: false,
                isIncluindoExame: false,
                showIncluirExame: true,
                incluindoExameIndex: 0,

                procurarExamePor: localStorage.procurarExamePor ?? 'apelido',
                procurarExameTexto: '',
                procurarGrupoTexto: '',
                procurarMaterialTexto: '',
                procurarConservanteTexto: '',
                exameCodigoTipoId: null,

                exameSelecionado: [],
                amostraSelecionada: [],
                estudoSelecionado: [],
                activeItemTab: 0,

                listaExames: null,
                grupoExames: null,
                listaCondicoesPagamento: null,

                filteredMedicamentos: [],
                locais: [],
               
                cid10type: null,
                cid10loading: false,
                cid10message: null,
                cid10descricao: null,

                validaUnidade: null,

                isLoadingRespostas: false,
                isExterno: false,

                vincularGuiaId: null,
                uploadingAnexo: null,
                descontoMensagem: null,

                incluiuNovoMedico: false,
                medicoCboss: [],
                mostraDetalhesExameId: null,

                modelosComprovante: [],
                modelosResultado: [],

                tabs: 'observacoes',
                pagamentoGuia: false,                
                totalExames: 0,
                descontoPorExame: 0,
                agendamentoDashboard: this.$route.params.agendamentoDashboard, 
                observacoesInspecao: this.$route.params.observacoesInspecao ? this.$route.params.observacoesInspecao : null,  
                adicionarOutrasDespesas: 0,               

                observacoesConvenio: "",
                indexSadt: 0,
                uploadKey: 0,
                fluxoAgenda: this.$route.params.fluxoAgenda ?? false,
                agendamentoId: this.$route.params.agendamento ?? null,
                convenioAnterior: null,
                examesBloqueados: [],
            }
        },
        components: {
                        titlebar,
            guiaindicadores,
            guiaAmostra,
            guiaExame,
            estudo,
            searchIdName,
            respostasPesquisasVue,
            draggable,
        },
        computed: {
            tooltipLabel() {
            if (this.guia.cnes === null || this.guia.cnes === '') {
                return 'CNES: '; 
            } else {
                return this.$t('GUIAATENDIMENTO.CNES') + ' ' + this.guia.cnes;
            } 
            },
            obterQuantidadeOutrasDespesas() {
                return (exame) => {
                    const quantidadePadrao = 1;
                    const quantidade = parseFloat(exame.observacao);
                    if (!isNaN(quantidade)) return quantidade;
                    return quantidadePadrao;
                }
            },
            precoExameCalculadoComQuantidade()
            {
                return (exame) => {
                    if (exame.exameDeOutrasDespesas == false) return exame.preco
                    else return exame.precoOriginal * this.obterQuantidadeOutrasDespesas(exame)
                }
            },
            unidadeNome() {
                const unidade = this.unidades?.find(x => x.id == this.guia.unidadeDeCadastroId);
                return unidade?.nome;
            },
            codigoIntegracaoNome(){
                const integrador = this.integradores?.find(x => x.id == this.guia.integradorId);
                return integrador?.nome;
            },
            titleStack() {
                return [
                    this.$t('GUIAATENDIMENTO.ATENDIMENTO'),
                    this.$t('GUIAATENDIMENTO.GUIA'),
                    this.guia.id ?? this.$t('GUIAATENDIMENTO.NOVA')
                ]
            },
            ...mapState([
                'unidadeId',
                'config',
                'integradores',
                'unidades',
                'tipoAgendamento',
            ]),
            ...mapState('componentes', [
                'novoMedico'
            ]),
            ...mapState('senhaAtendimento', [
                'atendendo'
            ]),
            ...mapGetters([
                'isInRole'
            ]),
            totalExamesDesconto(){
                if(this.guia.desconto != null && this.guia.desconto != '' && this.guia.desconto != undefined) {
                    if(this.exames.filter(exa => exa.exameDesconto != null && exa.exameDesconto != "" && exa.exameDesconto != undefined).length > 0) {
                        this.exames.forEach(exame => {
                            exame.exameDesconto = 0;
                            exame.preco = parseFloat(exame.precoTotalExame);
                        });
                    }

                    if((this.guia.desconto + "").indexOf("%") != -1 && !isNaN(parseFloat((this.guia.desconto + "").replace("%", "").replace(",", ".")))) {
                        
                        return (parseFloat(this.totalExames) - 
                            (parseFloat(this.totalExames) * 
                            (parseFloat((this.guia.desconto + "").replace("%", "").replace(",", ".")) / 100) ) );
                    } else if(!isNaN(parseFloat((this.guia.desconto + "").replace(",", ".")))) {

                        return (parseFloat(this.totalExames) - parseFloat((this.guia.desconto + "").replace(",", ".")));
                    }
                }
                else if(this.exames.filter(exa => exa.exameDesconto != null && exa.exameDesconto != "" && exa.exameDesconto != undefined).length > 0) {
                    let totalDesconto = 0;
                    this.exames.forEach(exame => {
                        totalDesconto += parseFloat(exame.preco);
                    });
                    return totalDesconto;
                }

                return this.totalExames;
            },    
            examesQuePodemCancelarDigitacao()
            {
                return this.exameSelecionado.every(exame => exame.digitado && !exame.assinado && !exame.bloqueado && !exame.cancelado)
            }       
        },
        mounted() {
            document.addEventListener('visibilitychange', this.verificaRetornarMedico, false);            
            this.loadGuia();
            if (this.unidadeId) {
                this.ValidaUnidade();
            }
        },
        watch: {
            // call again the method if the route changes
            '$route': 'loadGuia',
            isIncluindoExame: function () {
                if(!this.isIncluindoExame) {
                    this.adicionarOutrasDespesas = 0;
                    this.listaExames = null;
                }
            },
            '$route.params.id': function (newId) {
                this.loadGuia(newId); 
            },
            'guia.convenioId': function(novoVal, antigoVal) {
                this.convenioAnterior = antigoVal;
                if (novoVal !== null && (antigoVal != null || antigoVal != undefined) && novoVal !== antigoVal) {
                    this.alterouConvenio(); 
                    this.loadPrecosExames();
                    this.totalExames = this.totalExamesCalc();
                }
            }
        },
        methods: {
            alterouConvenio(){
                const params = []; 
                const exames = this.exames.map(x => x.exameId).join(',');
                
                params.push(`convenioId=${this.guia.convenioId}`);
                params.push(`examesIds=${exames}`);
                params.push(`guiaId=${this.guia.id}`);

                if(exames != ""){                    
                    this.$http.get(`/api/atendimento/VerificaExameBloqueado?${params.join('&')}`)
                        .then(res => {
                            this.examesBloqueados = res.data.examesBloqueados;
                            if(this.examesBloqueados != null && this.examesBloqueados.length>0){
                                const examesFormatados = '<br>' + this.examesBloqueados.map(exame => `<strong>${exame}</strong>`).join(', ');
                                this.$buefy.dialog.alert({
                                    title: this.$t('GUIAATENDIMENTO.EXAMESBLOQUEADOS'),
                                    message: this.$t('GUIAATENDIMENTO.EXAMESBLOQUEADOSMSG', {'0': examesFormatados}),
                                    type: 'is-info',
                                    hasIcon: true,
                                    onConfirm: () => {
                                        this.guia.convenioId = this.convenioAnterior == null ? "" : this.convenioAnterior;
                                    }
                                });
                            }
                        })
                }
            },
            ValidaUnidade() {
                return this.$http.get(`/api/search/UnidadeAtiva?id=${this.unidadeId}`)
                    .then(res => {
                        if (res.data === false) {
                            this.validaUnidade = false;
                            return false;
                        } else {
                            this.validaUnidade = true;
                            console.log("Unidade está ativa");
                            return true;
                        }
                    })
                    .catch(error => {
                        console.error("Erro ao verificar unidade:", error);
                        return false;
                    });
            },
            atualizaPrecoQuandoMudaQuantidade(exame)
            {
                if (this.guia.convenioTipoParticular)
                {
                    this.exames.map(async (x) => {
                        if (x.item == 0 && x.exameId == exame.exameId && x.preco != null && x.preco != undefined) {
                            x.preco = this.precoExameCalculadoComQuantidade(x)
                            x.precoTotalExame = x.preco
                        } else if (x.exameDeOutrasDespesas && x.exameId == exame.exameId && x.preco != null && x.preco != undefined) {
                            if (x.precoOriginal == undefined)
                            {
                                const examesPreco = {
                                exames: [
                                        {
                                            exameId: x.exameId,
                                            setorId: x.setorId,
                                            preco: 0.0,
                                            itemExame: x.item,
                                            urgente: x.urgente
                                        }
                                    ],
                                    convenioId: this.guia.convenioId
                                }

                                const resposta = await this.$http.post('/api/manutencao/ExamesCalculaPrecoParticular', examesPreco)
                                const exameComPrecoOriginal = resposta.body.exames.shift();
                                x.precoOriginal = exameComPrecoOriginal.preco
                            }
                            x.preco = this.precoExameCalculadoComQuantidade(x)
                            x.precoTotalExame = x.preco
                        }
                    });

                }

                this.totalExames = this.totalExamesCalc();
                this.$forceUpdate();
            },
            verificaAmostrasColeta(){
                let mostrarOpcaoCancelarColeta = false;

                this.amostraSelecionada.forEach(a => {
                       a.itens.forEach(e => {
                            if(e.guiaExame.materialEntregue && (!e.guiaExame.digitado && !e.guiaExame.assinado))
                                mostrarOpcaoCancelarColeta = true;
                    });                    
                });

                return mostrarOpcaoCancelarColeta
            },
            verificaRetornarMedico() {                                 
                if(localStorage.retornarMedico > 0){                                        
                    this.guia.medicoId = localStorage.retornarMedico;                    
                    localStorage.retornarMedico = null;
                }                                
            }, 
            buscaLocaisGuia(){
                this.$http.get('/api/search/GuiaLocais' + ((this.$route.params.id || this.$route.params.referencia || this.$route.params.amostra || this.$route.params.amostraReferencia || this.$route.params.clonarGuiaId) ? '?mostrarDesativados=true' + '&localSalvo=' + this.guia.localDeOrigemId : '') + (this.$route.params.pacienteId ? '?unidadeId=' : '&unidadeId=') + this.guia.unidadeDeCadastroId + '&convenioPadrao=' + this.guia.convenioId)
                .then(res => {
                    this.locais = res.data
                    this.loadInformacoesGuia()

                    const convenioPadraoLocal = this.locais.filter(local => local.convenioId != null && this.guia.convenioId != null ? local.convenioId == this.guia.convenioId : local.unidadeId == this.guia.convenioUnidadeId);
                    const unidadePadraoLocal = this.locais.filter(local => local.unidadeId == this.guia.unidadeDeCadastroId);

                    if(convenioPadraoLocal.length > 0 && convenioPadraoLocal != null){
                        this.guia.localDeOrigemId = convenioPadraoLocal[0].id;
                    } else if(unidadePadraoLocal.length > 0 && unidadePadraoLocal != null && unidadePadraoLocal > 0) {
                        this.guia.localDeOrigemId = unidadePadraoLocal[0].id;
                    } 
                });
            },         
            salvarGuia() {
                if(this.guia.desconto != null && this.guia.desconto != '' && this.guia.desconto != undefined) { 
                    if((this.guia.desconto + "").indexOf("%") != -1) {
                        this.guia.desconto = parseFloat(this.totalExames) - parseFloat(this.totalExamesDesconto)
                    }
                    else {
                        this.guia.desconto = (this.guia.desconto + "").replace(",", ".")
                    }
                    
                    this.guia.desconto = parseFloat(this.guia.desconto).toFixed(2);

                    this.exames.forEach(exame => {
                        exame.exameDesconto = 0;
                    });
                }
                else if(this.exames.filter(exa => exa.exameDesconto != null && exa.exameDesconto != "" && exa.exameDesconto != undefined).length > 0) {
                    this.exames.forEach(exame => {
                        if(exame.exameDesconto == null || exame.exameDesconto == "") {
                            exame.exameDesconto = 0;
                        }
                        else if((exame.exameDesconto + "").indexOf("%") != -1) {
                            exame.exameDesconto = parseFloat(exame.precoTotalExame) - parseFloat(exame.preco)
                        }
                        else {
                            exame.exameDesconto = (exame.exameDesconto + "").replace(",", ".")
                        }
                        exame.exameDesconto = parseFloat(exame.exameDesconto).toFixed(2);
                    });
                }



                this.alterouLocalDeOrigem();

                if(this.guia.localDeOrigemId == null || this.guia.localDeOrigemId == '' || this.guia.localDeOrigemId == undefined){
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIAATENDIMENTO.TITULOLOCALORIGEM'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMLOCALORIGEM'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    return;
                }

                if (this.guia.medicoId == 0 || this.guia.medicoId == null || this.guia.medicoId == '' || this.guia.medicoId == undefined) {
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIAATENDIMENTO.MEDICOOBRIGATORIO'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMMEDICOOBRIGATORIO'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    return;
                }
                
                if(!this.guia.convenioCoparticipacao){
                    if (this.pagamentoGuia == true && this.guia.condicaoPagamento == null && this.agendamentoDashboard != "true"
                    && this.exames.filter(x => !x.faturado && !x.cancelado && x.copiaDe == null || (x.copiaDe != null && !this.config.naoFaturarExamesVinculados) ).length > 0  ) {     
                        this.$buefy.dialog.alert({
                            title: this.$t('GUIAATENDIMENTO.CONDICAOPAGAMENTO'),
                            message: this.$t('GUIAATENDIMENTO.MENSAGEMCONDICAOPAGAMENTO'),
                            type: 'is-warning',
                            hasIcon: true
                        });
                        this.redirecionarPagamentoGuia();
                        return;
                    }
                }

                if(this.exames == null || this.exames.length == 0){
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIAATENDIMENTO.TITULOEXAMESOBRIGATORIOS'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMEXAMESOBRIGATORIOS'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    return;
                }

                if(this.guia.inscricaoObrigatoria && (this.guia.pacienteInscricao == null || this.guia.pacienteInscricao == '')){
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIAATENDIMENTO.TITULOINSCRICAOOBRIGATORIA'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMINSCRICAOOBRIGATORIA'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    return;
                }

                if (this.exames.filter(x => x.informarMedicamento == true).length > 0 && (this.guia.medicamentos == null || this.guia.medicamentos.length == 0)) {
                    this.tabs = 'medicamentos';
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIAATENDIMENTO.MEDICAMENTOSOBRIGATORIOS'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMMEDICAMENTOSOBRIGATORIOS'),
                        type: 'is-info',
                        hasIcon: true
                    });
                }

                if (this.exames.filter(x => x.informarHipoteseDiagnostica == true).length > 0 && (this.cid10descricao == null || 
                        (this.guia.indicacaoClinica == "" || this.guia.indicacaoClinica == undefined || this.guia.indicacaoClinica == null))) {
                    this.tabs = 'hipotese';
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIAATENDIMENTO.HIPOTESEDIAGNOSTICAOBRIGATORIA'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMHIPOTESEDIAGNOSTICA'),
                        type: 'is-info',
                        hasIcon: true
                    });
                }
                else if (this.exames.filter(x => x.informarDUM == true).length > 0 && this.guia.pacienteSexo == 'F'
                    && (this.guia.dataDaUltimaMenstruacao == null)) {
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIAATENDIMENTO.DATAMENSTRUACAO'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMDATAMENSTRUACAO'),
                        type: 'is-info',
                        hasIcon: true
                    });
                }
                else {
                    this.enviaSalvarGuia();
                }
                
            },
            enviaSalvarGuia() {
                localStorage.procurarExamePor = this.procurarExamePor;
                
                this.salvandoDados = true;
                if (this.guia.unidadeDeCadastroId == null || this.guia.unidadeDeCadastroId==0  ) {                    
                    this.guia.unidadeDeCadastroId = this.unidadeId;
                }
                if(this.guia.integradorId == null || this.guia.integradorId == 0) {
                    this.guia.integradorId = null;
                }
                this.guia.exames = this.exames
                this.guia.exames.forEach(x => {
                    x.precoTotal = this.exames.find(e => e.item == x.item).precoOriginal;
                });
                this.guia.pagamentoGuia = this.pagamentoGuia
                this.exames.forEach(x => {
                    const filteredsadt = this.sadts.filter(x => !x.exameDeOutrasDespesas && !x.cancelado)
                    const sadt = filteredsadt.find(z => z.exames.find(exame  => exame.item === x.item) );
                    if(sadt){                            
                        x.sadtNumero = sadt.numero;
                        x.sadtAutorizacaoSenha = sadt.autorizacaoSenha;
                        x.sadtAutorizacao = sadt.autorizacao;
                        x.sadtAutorizacaoSenhaValidade = sadt.autorizacaoSenhaValidade;
                        x.index = sadt.index;
                    }                                  

                    delete x.agendaEvento;
                });

                if (this.atendendo != undefined) {
                    this.guia.senhaDeAtendimento = this.atendendo.id
                }
                if (this.$route.params.agendamentoDashboard) {
                    this.guia.agendamentoDashboard = this.agendamentoDashboard;
                }


                const agendar = (this.$route.params.agendar) ? this.$route.params.agendar : null ;
                if(agendar && agendar.length > 0 ) { 
                
                    this.guia.agendarExames = [];
                    
                    agendar.forEach(a => {
                        this.guia.agendarExames.push({ eventoId: a.evento.id, examesIds: a.examesIds.map(e => parseInt(e)) });                                            
                                              
                    }); 
                                        
                }
                
                if(this.config.travarGuiaAutorizacaoObrigatoria && !this.guia.convenioControleDeGuiasSadt ) {
                    let requerAutorizacao = null;
                    let requerSenha = null; 


                    this.exames.filter(x => x.requerAutorizacao || x.requerSenha).forEach(validaAutorizacao => {
                        requerAutorizacao = validaAutorizacao.requerAutorizacao  ?
                                this.guia.autorizacaoGuia == null 
                                ||  this.guia.autorizacaoGuia == ""
                            ? true : false
                        : false;
                        
                        requerSenha = validaAutorizacao.requerSenha ?
                                this.guia.autorizacaoGuia == null 
                                || this.guia.autorizacaoGuia == ""
                                || this.guia.autorizacaoSenha == null
                                || this.guia.autorizacaoSenha == ""
                                || this.guia.autorizacao == null
                                || this.guia.autorizacao == ""
                                || this.guia.autorizacaoSenhaValidade == null
                                || this.guia.autorizacaoSenhaValidade == ""
                            ? true : false
                        : false;

                       
                    });

                    if(requerAutorizacao || requerSenha) {
                        this.salvandoDados = false;
                        return this.$buefy.toast.open({
                            duration: 10000,
                            message: this.$t('GUIAATENDIMENTO.PREENCHERAUTORIZACAO'),
                            type: 'is-danger',
                            queue: false
                        });
                    }


                }

                let examesDeImagem = this.exames.filter(e => e.exameSetorExamesDeImagem == true || e.exameSetorDeImagem == true);

                const examesUnicos = {};
                examesDeImagem = examesDeImagem.filter(e => {
                    if(!examesUnicos[e.exameId]){
                        examesUnicos[e.exameId] = true;
                        return true;
                    }
                    return false;
                });
                
                if (this.validaUnidade) {
                    this.$http.post('/api/atendimento/edit', this.guia)
                        .then(res => {
                            this.salvandoDados = false;
                            this.$store.commit('senhaAtendimento/SET_ATENDENDO', undefined)

                            if (res.data.verificarGuiaSadt) {
                                this.$router.push({ name: 'guia', params: { id: res.data.id } });

                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message: this.$t('GUIAATENDIMENTO.PREENCHERSADT'),
                                    type: 'is-danger',
                                    queue: false
                                });
                            }
                            else if (res.data.verificarExameDeMesmoPaciente) {
                                this.$router.push({
                                    name: 'exames=mesmo-paciente',
                                    params: {
                                        id: res.data.id,
                                        pagamentoGuia: res.data.pagamentoGuia,
                                        condicaoPagamento: res.data.condicaoPagamento,
                                        convenioCoparticipacao: this.guia.convenioCoparticipacao && this.guia.id == 0,
                                        agendamentoDashboard: this.agendamentoDashboard,
                                        examesDeImagem: examesDeImagem,
                                        fluxoAgenda: this.fluxoAgenda ?? false,
                                        agendamentoId: this.agendamentoId ?? null
                                    }
                                });
                            }
                            else if (res.data.pesquisaClinica) {
                                this.$router.push({
                                    name: 'pesquisaclinica',
                                    params: {
                                        id: res.data.id,
                                        pagamentoGuia: res.data.pagamentoGuia,
                                        condicaoPagamento: res.data.condicaoPagamento,
                                        convenioCoparticipacao: this.guia.convenioCoparticipacao && this.guia.id == 0,
                                        pesquisaPosGuia: true,
                                        agendamentoDashboard: this.agendamentoDashboard
                                    }
                                });
                            }
                            else if (res.data.id && res.data.worklist) {
                                this.$router.push({
                                    name: 'worklist',
                                    params: {
                                        id: res.data.id,
                                        pagamentoGuia: res.data.pagamentoGuia,
                                        condicaoPagamento: res.data.condicaoPagamento,
                                        convenioCoparticipacao: this.guia.convenioCoparticipacao && this.guia.id == 0,
                                        agendamentoDashboard: this.agendamentoDashboard,
                                        examesDeImagem: examesDeImagem,
                                        fluxoAgenda: this.fluxoAgenda ?? false,
                                        agendamentoId: this.agendamentoId ?? null
                                    }
                                });
                            }
                            else if (res.data.pagamentoGuia && this.agendamentoDashboard != "true") {
                                this.$router.push({
                                    name: 'cadastro-fatura-particular',
                                    params: {
                                        guiaId: res.data.guiaId,
                                        condicaoPagamento: res.data.condicaoPagamento,
                                        convenioCoparticipacao: this.guia.convenioCoparticipacao && this.guia.id == 0,
                                        agendamentoDashboard: this.agendamentoDashboard
                                    }
                                });
                            }
                            else if (res.data.id) {
                                this.$router.push({
                                    name: 'workflow',
                                    params: {
                                        id: res.data.id,
                                        agendamentoDashboard: this.agendamentoDashboard
                                    }
                                });
                            }
                        })
                        .catch(err => {
                            this.salvandoDados = false
                            // restrições guia
                            if (err.status == 404) {
                                if (err.body.indexOf('peso') > 0) {
                                    this.$buefy.dialog.prompt({
                                        title: this.$t('GUIAATENDIMENTO.PESO'),
                                        message: this.$t('GUIAATENDIMENTO.MENSAGEMPESO'),
                                        inputAttrs: {
                                            type: 'number',
                                            placeholder: 'Peso'
                                        },
                                        confirmText: this.$t('SISTEMA.CONFIRMAR'),
                                        cancelText: this.$t('GUIAATENDIMENTO.CANCELAR'),
                                        trapFocus: true,
                                        onConfirm: (res) => {
                                            this.guia.pacientePeso = res;
                                            this.enviaSalvarGuia();
                                        }
                                    });
                                }
                                else if (err.body.indexOf('altura') > 0) {
                                    this.$buefy.dialog.prompt({
                                        title: this.$t('GUIAATENDIMENTO.ALTURA'),
                                        message: this.$t('GUIAATENDIMENTO.MENSAGEMALTURA'),
                                        inputAttrs: {
                                            type: 'number',
                                            placeholder: 'Altura'
                                        },
                                        confirmText: this.$t('SISTEMA.CONFIRMAR'),
                                        cancelText: this.$t('GUIAATENDIMENTO.CANCELAR'),
                                        trapFocus: true,
                                        onConfirm: (res) => {
                                            this.guia.pacienteAltura = res;
                                            this.enviaSalvarGuia();
                                        }
                                    });
                                }
                                else if (err.body.indexOf('horas do exame') > 0) {
                                    const errorMessages = err.body.split('. ').join('<br>');
                                    const mensagem = `<strong>${this.$t('GUIA.TEMPOMINIMO')}</strong>` + '<br>' + '<br>' + errorMessages;

                                    this.$buefy.dialog.confirm({
                                        message: mensagem,
                                        confirmText: this.$t('SISTEMA.CONFIRMAR'),
                                        type: 'is-info',
                                        hasIcon: true,
                                        cancelText: this.$t('GUIAATENDIMENTO.CANCELAR'),
                                        onConfirm: () => {
                                            this.addIncidenciaAmostraExame(err.body);
                                        }
                                    });
                                }
                                else if (err.body.indexOf('médico') > 0) {
                                    this.$buefy.toast.open({
                                        duration: 10000,
                                        message: err.body,
                                        type: 'is-danger',
                                        queue: false
                                    });
                                }
                                else {
                                    this.$buefy.toast.open({
                                        duration: 10000,
                                        message: err.body,
                                        type: 'is-danger',
                                        queue: false
                                    });
                                }
                            }
                            else {

                                const primeiralinha = err.body.substring(0, err.body.indexOf('\n'))
                                console.log('ERRO:' + err.body)

                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message: primeiralinha,
                                    type: 'is-danger',
                                    queue: false
                                })
                            }
                        })
                } else {
                    this.$router.push('/home').then(() => {
                    window.location.reload();
                });
                }
            },
            addIncidenciaAmostraExame(msgErr){
                const exameSemJejum = this.exames.filter(exame => msgErr.includes(exame.exameApelido) && exame.item == 0);

                if(exameSemJejum.length > 0){
                    this.$buefy.modal.open({
                        parent: this,
                        component: modalIncidenciaAmostra,
                        props: {
                            guia: this.guia,
                            selecionada: exameSemJejum,
                            titulo: this.$t('GUIA.EXAMES'),
                            salvarGuia: true,
                        },
                        events: {
                            loadGuia: this.loadGuia,
                            salvarGuia: this.enviaSalvarGuia
                        },
                        hasModalCard: true,
                        trapFocus: true
                    })
                }
            },
            limparSelecionados() {
                this.amostraSelecionada = []
                this.exameSelecionado = []
                this.estudoSelecionado = []
            },
            guiaExameInfo(item) {
                return this.exames.find(x => x.item === item)
            },
            loadGuia() {
                this.limparSelecionados()
                this.sadts = []
                this.respostasPesquisas = null
                this.historicoAlteracoes = null
                this.guiasFilhas = null
                this.activeItemTab = 0

                if (this.$route.params.pacienteId) {
                    this.$http.get('/api/atendimento/create?pacienteId=' + this.$route.params.pacienteId + '&unidadeId=' + this.unidadeId)
                    .then(res => {
                        this.guia = res.data;

                        if(this.$route.params.agendamento){
                            this.guia.agendamento = this.$route.params.agendamento;
                        }

                        if(this.$route.params.tipoAgendamentoNome){
                            this.guia.tipoAgendamentoNome = this.$route.params.tipoAgendamentoNome;
                        }

                        if(this.$route.params.convenioIdAgendar){
                            this.guia.convenioId = this.$route.params.convenioIdAgendar;
                        }
                        
                        this.temNovoMedico()
                        this.loadInformacoesGuia()
                        this.temNovoMedico()
                        this.buscaLocaisGuia()
                    })

                    const agendar = (this.$route.params.agendar) ? this.$route.params.agendar : null ;
                    if(agendar && agendar.length > 0 ) {                    
                        const examesAgenda = [];
                        agendar.forEach(a => {
                            a.examesIds.forEach(e => {                                                                   
                                examesAgenda.push({
                                    eventoId: a.evento.id,
                                    exameId: e
                                });
                            });                        
                        });

                        const serializedParams = encodeURIComponent(JSON.stringify(examesAgenda));

                        this.$http.get('/api/atendimento/listarExameAgenda?examesAgenda='+ serializedParams)
                        .then(res => res.data)
                        .then(data => {
                            let localUrgente = false;

                            const local = this.locais.filter(x => x.id == this.guia.localDeOrigemId)[0];
                            if(local){
                                localUrgente = local.urgente;
                            }
                            data.exame.forEach(e => {                                
                                this.exames.push({
                                    item: 0,
                                    exameId: e.id,
                                    exameApelido: e.apelido,
                                    exameGenero: e.genero,
                                    exameNome: e.nome,
                                    exameSetorId: e.setorId,
                                    exameSetorNome: e.setorNome,
                                    exameSetorExamesDeImagem: e.setorExamesDeImagem,
                                    exameSetorNotificarExames: e.setorNotificarExames,
                                    recipienteId: e.recipienteId,
                                    dataDeSolicitacao: new Date(),
                                    materialEntregue: (e.exameDeFaturamento || e.setorExamesDeImagem || e.exameDeOutrasDespesas ) ? false : localStorage.materialEntregue == "true", // localstorage armazena em string
                                    urgente: localUrgente == true ? true : localStorage.caraterUrgencia == "true", // localstorage armazena em string
                                    informarMedicamento: e.informarMedicamento,
                                    informarHipoteseDiagnostica: e.informarHipoteseDiagnostica,
                                    informacoesPaciente: e.informacoesPaciente,
                                    minimoJejum: e.minimoJejum,
                                    informarPeso: e.informarPeso,
                                    informarAltura: e.informarAltura,
                                    informarDUM: e.informarDUM,
                                    requerAutorizacao: e.requerAutorizacao,
                                    requerSenha: e.requerSenha,
                                    exameDeFaturamento: e.exameDeFaturamento,
                                    exameDeOutrasDespesas: e.exameDeOutrasDespesas,
                                    quantidadeUs: e.quantidadeUS,
                                    exameDesconto: null,
                                    agendaEvento: e.agendaEvento,
                                    exameUrgente: e.urgente
                                })
                            })
                        })                  
                    }

                    
                }
              
                if (this.$route.params.id) {

                    this.$http.get('/api/atendimento/edit?id=' + this.$route.params.id)
                        .then(res => {
                            this.convenioAtual = res.data[0].convenioId;
                            this.temNovoMedico()
                            this.loadGuiaExames(res.data[0])
                            this.buscaLocaisGuia()
                            this.getCid10()
                        })
                        .catch(() => {
                            this.$buefy.toast.open({
                                duration: 10000,
                                    message: this.$t('ATENDIMENTO.GUIA') + this.$t('ATENDIMENTO.NAOENCONTRADA'),
                                type: 'is-danger',
                                queue: false
                            }); 
                           
                            this.$router.push('/atendimento')
                        })
                }

                if (this.$route.params.referencia) {

                    this.$http.get('/api/atendimento/AbrirGuiaReferencia?referencia=' + this.$route.params.referencia)
                        .then(res => {
                            this.temNovoMedico()
                            this.loadGuiaExames(res.data[0])
                            this.buscaLocaisGuia()
                        })
                }

                if (this.$route.params.amostra) {

                    this.$http.get('/api/atendimento/AbrirGuia?codigoDeBarras=' + this.$route.params.amostra)
                        .then(res => {
                            this.temNovoMedico()
                            this.loadGuiaExames(res.data[0])
                            this.buscaLocaisGuia()
                        })
                }

                if (this.$route.params.amostraReferencia) {

                    this.$http.get('/api/atendimento/AbrirGuiaAmostraReferencia?amostraReferencia=' + this.$route.params.amostraReferencia)
                        .then(res => {
                            this.temNovoMedico()
                            this.loadGuiaExames(res.data[0])
                            this.buscaLocaisGuia()
                        })
                }
                
                if (this.$route.params.clonarGuiaId) {

                    this.$http.get('/api/atendimento/create?guiaId=' + this.$route.params.clonarGuiaId)
                        .then(res => {
                            this.guia = res.data
                            this.loadInformacoesGuia()
                            this.temNovoMedico()
                            this.buscaLocaisGuia()
                        })
                }
                

                this.$http.get('/api/atendimento/ListarComprovanteModelos')
                    .then(res => {
                        this.modelosComprovante = res.data;
                    })

                this.$http.get('/api/atendimento/ListarResultadoModelos')
                    .then(res => {
                        this.modelosResultado = res.data;
                    })

            },
            temNovoMedico() {
                if(this.$route.params.solicitanteId){
                    this.guia.medicoId = this.$route.params.solicitanteId
                }
                
                if (this.novoMedico != undefined) {
                    this.incluiuNovoMedico = true
                    this.guia.medicoId = this.novoMedico.id
                    this.guia.medicoNome = this.novoMedico.nome
                    this.medicoCboss = this.novoMedico.cboss
                    if(this.medicoCboss != null && this.medicoCboss.length > 0 && this.guia.medicoCBOSId == null){
                        this.guia.medicoCBOSId = this.medicoCboss[0].id
                    }

                    this.$store.commit('componentes/SET_NOVO_MEDICO', undefined)
                } else {
                    if (this.guia.medicoId) {
                        this.loadMedicoCbos()
                    }
                }
            },
            alterouLocalDeOrigem() {
                if (this.guia.localDeOrigemId != null && this.guia.id == 0) {
                    localStorage.localDeOrigemId = this.guia.localDeOrigemId;
                }
            },
            loadInformacoesGuia() {
                if (this.guia.pacienteId == null) {
                    this.$buefy.toast.open({
                        message: this.$t('GUIAATENDIMENTO.PACIENTEDESATIVADO'),
                        type: 'is-danger',
                        duration: 10000
                    })
                    this.$router.push('/atendimento')
                }

                if (this.guia.dataDeEmissao != null) {
                    this.guia.dataDeEmissao = new Date(this.guia.dataDeEmissao);
                }
                else {
                    this.guia.dataDeEmissao = new Date();
                }

                if (this.guia.dataDeAlta != null) {
                    this.guia.dataDeAlta = new Date(this.guia.dataDeAlta);
                }

                if (this.guia.dataDaUltimaMenstruacao != null) {
                    this.guia.dataDaUltimaMenstruacao = new Date(this.guia.dataDaUltimaMenstruacao);
                }

                if (this.guia.dataDeRetorno != null) {
                    this.guia.dataDeRetorno = new Date(this.guia.dataDeRetorno);
                    this.guia.dataDeRetornoAnterior = this.guia.dataDeRetorno;
                }

                if (localStorage.localDeOrigemId != null && this.guia.id == 0 && this.config.armazenaUltimoLocalDeOrigemCadastradoNaGuia) {
                    this.guia.localDeOrigemId = localStorage.localDeOrigemId;
                }

                if(this.guia.autorizacao != null){
                    this.guia.autorizacao = new Date(this.guia.autorizacao);
                }

                if(this.guia.autorizacaoSenhaValidade != null){
                    this.guia.autorizacaoSenhaValidade = new Date(this.guia.autorizacaoSenhaValidade);
                }

            },
            mostraDetalhes(id, event) {
                event.stopPropagation();
                if (this.mostraDetalhesExameId == id) {
                    this.mostraDetalhesExameId = null;
                }
                else {
                    this.mostraDetalhesExameId = id;
                }
            },
            loadMedicoCbos() {
                this.$http.get('/search/MedicoCboss?id=' + this.guia.medicoId)
                    .then(res => {
                        this.medicoCboss = res.data
                        if(this.medicoCboss != null && this.medicoCboss.length > 0 && this.guia.medicoCBOSId == null){
                            this.guia.medicoCBOSId = this.medicoCboss[0].id
                        }
                    })
            },
            escolheuMedico(val){
                if(val != null && val.data != null && val.data.cboss != null){
                    const cboss = val.data.cboss.split(',');
                    this.medicoCboss = [];
                    this.guia.medicoCBOSId = null;

                    cboss.forEach(item => {
                        this.medicoCboss.push({
                            id: item.split('_')[0],
                            codigo: item.split('_')[1]
                        });
                    });

                    if(this.medicoCboss != null && this.medicoCboss.length > 0 && this.guia.medicoCBOSId == null){
                        this.guia.medicoCBOSId = this.medicoCboss[0].id
                    }
                }
            },
            async loadGuiaExames(guia) {
                await this.$http.get('/api/atendimento/GuiaExamesEdit?guiaId=' + guia.id)
                    .then(resexa => {
                        this.exames = resexa.data?.map(e => {
                                if(e.exameDesconto != null) {
                                    e.precoTotalExame = (parseFloat(e.preco) + parseFloat(e.exameDesconto ?? 0)).toFixed(2);
                                }
                                
                                return {
                                    ...e,
                                  sadtAutorizacao : (e.sadtAutorizacao)  ? new Date(e.sadtAutorizacao) : null,
                                  sadtAutorizacaoSenhaValidade : (e.sadtAutorizacaoSenhaValidade) ? new Date(e.sadtAutorizacaoSenhaValidade) : null,
                                }
                            });                        
                        this.loadGuiaAmostras(guia.id)
                        this.loadGuiaEstudos(guia.id)
                        this.loadGuiaSadts()
                        this.loadAnexos()
                    })
                    
                this.guia = guia
                this.loadInformacoesGuia();
            },
            loadGuiaAmostras(id) {
                this.$http.get('/api/atendimento/GuiaAmostrasEdit?guiaId=' + id)
                    .then(resamos => {
                        this.amostras = resamos.data

                        if(this.amostras.filter(amostra => amostra.incidencia).length > 0){
                            this.guia.incidencia = true;

                            this.amostras.forEach(amostra => {
                                if(amostra.incidencia){
                                    amostra.itens.forEach(item => {
                                        if(this.exames.filter(exame => exame.item == item.guiaExame.item && id == item.guiaExame.guiaId).length > 0){
                                            this.exames.filter(exame => exame.item == item.guiaExame.item && id == item.guiaExame.guiaId)[0].statusTitle = this.exames.filter(exame => exame.item == item.guiaExame.item && id == item.guiaExame.guiaId)[0].statusTitle + ", Incidência"
                                            this.exames.filter(exame => exame.item == item.guiaExame.item && id == item.guiaExame.guiaId)[0].incidencia = true
                                        }
                                    })
                                }
                            });
                        }
                    })
            },
            loadGuiaEstudos(id) {
                this.$http.get('/api/atendimento/GuiaEstudos?guiaId=' + id)
                    .then(resestudos => {
                        this.estudos = resestudos.data;               
                    })
            },
            loadGuiaSadts() {
                
                const helper = {}
                this.exames.filter(x => !x.exameDeOutrasDespesas && !x.cancelado).forEach(x => {
                    if (!helper[x.sadtId]) {
                        helper[x.sadtId] = {
                            id: x.sadtId,
                            numero: x.sadtNumero,
                            autorizacao: x.sadtAutorizacao,
                            autorizacaoSenha: x.sadtAutorizacaoSenha != null ? x.sadtAutorizacaoSenha : null,
                            autorizacaoSenhaValidade: x.sadtAutorizacaoSenhaValidade != null ? new Date(x.sadtAutorizacaoSenhaValidade) : null,
                            exames: [{
                                sadtId: x.sadtId,
                                id: x.exameId,
                                apelido: x.exameApelido,
                                item: x.item
                            }]
                        }
                    } else {
                        helper[x.sadtId].exames.push({                            
                            id: x.exameId,
                            apelido: x.exameApelido,
                            item: x.item
                        })
                    }
                })
                this.sadts = Object.values(helper)
            },
            loadGuiasFilhas() {
                this.$http.get('/api/atendimento/guiasFilhas?id=' + this.guia.id)
                    .then(res => {
                        this.guiasFilhas = res.data
                    })
            },
            loadAnexos() {
                this.uploadingAnexo = null
                this.anexos = null
                this.$http.get('/api/atendimento/anexos?id=' + this.guia.id)
                    .then(res => {
                        this.anexos = res.data
                    })
            },
            loadRespostasPesquisas() {
                this.isLoadingRespostas = true
                this.$http.get('/api/atendimento/respostasPerguntas?id=' + this.guia.id)
                    .then(res => {
                        res.data.forEach(x => {
                            x.respostas.forEach(resposta => {
                                
                                if(resposta.perguntaItem.aceitaData){
                                    resposta.descritivoData = resposta.descritivo != null ? new Date(resposta.descritivo) : null 
                                }
                            })
                        })

                        this.respostasPesquisas = res.data;
                    })
                    .finally(() => {
                        this.isLoadingRespostas = false
                    })
            },
            loadHistoricoAlteracoes() {
                this.$http.get('/api/manutencao/log?tabela=Guia&chave=' + this.guia.id)
                    .then(res => {
                        this.historicoAlteracoes = res.data
                    })
            },
            inputListaExames: debounce(function () {
                let outrasDespesas = null;
                if(this.adicionarOutrasDespesas != 0 && this.adicionarOutrasDespesas != null){
                    outrasDespesas = true;
                }

                this.loadListaExames(outrasDespesas)
            }, 500),
            loadListaExames(outrasDespesas, changeConvenio) {
                if (this.guia.convenioId) {
                    const params = {
                        texto: this.procurarExameTexto,
                        material: this.procurarMaterialTexto,
                        conservante: this.procurarConservanteTexto,
                        campo: this.procurarExamePor,
                        exameCodigoTipoId: this.guia.convenioTipoExameCodigoId,
                        convenioId: this.guia.convenioId,
                        outrasDespesas: outrasDespesas,
                    }
                    this.$http.get('/api/atendimento/EditGuiaListaExames', { params })
                        .then(res => {
                            this.listaExames = res.data
                            this.incluindoExameIndex = 0

                            if(changeConvenio) {
                                this.exames.forEach( exameIncluso => { 
                                    exameIncluso.requerSenha = this.listaExames.filter(listaExames => listaExames.id == exameIncluso.exameId)[0].requerSenha;
                                    exameIncluso.requerAutorizacao = this.listaExames.filter(listaExames => listaExames.id == exameIncluso.exameId)[0].requerAutorizacao
                                });
                            }
                        })
                }
            },
            inputListaGrupo: debounce(function () {
                let outrasDespesas = false;
                if(this.adicionarOutrasDespesas != 0 && this.adicionarOutrasDespesas != null){
                    outrasDespesas = true;
                }
                this.loadGrupoExames(outrasDespesas)
            }, 500),
            async loadGrupoExames(outrasDespesas) {
                if (this.guia.convenioId) {
                    const params = {
                        texto: this.procurarGrupoTexto,
                        convenioId: this.guia.convenioId,
                        outrasDespesas: outrasDespesas,
                        exameIdSelecionado: this.adicionarOutrasDespesas
                    }
                    await this.$http.get('/api/atendimento/EditGuiaGrupoExames', { params })
                        .then(res => {
                            this.grupoExames = res.data
                        })
                }
            },
            loadListaCondicoesPagamento() {
                this.$http.get('/api/search/CondicaoPagamento')
                    .then(res => {
                        this.listaCondicoesPagamento = res.data
                        if (this.listaCondicoesPagamento.length == 1) {
                            this.guia.condicaoPagamento = this.listaCondicoesPagamento[0]
                        }
                    })
            },
    
            loadPrecosExames() {
                const examesPreco = {
                    exames: this.exames.filter(x => !x.faturado).map(x => {
                        return{
                            exameId: x.exameId,
                            setorId: x.setorId,
                            preco: 0.0,
                            itemExame: x.item,
                            urgente: x.urgente
                        }
                    }),
                    convenioId: this.guia.convenioId
                }

                this.$http.post('/api/manutencao/ExamesCalculaPrecoParticular', examesPreco)
                .then(res => res.data)
                .then(data => {                        
                    this.exames.filter(x => !x.faturado).forEach(e => {
                        
                        const exame = data.exames.filter(x => x.exameId == e.exameId && (x.contabilizado == null))[0];

                        if(e.complementoDoExame){

                            const examePai = this.exames.filter(c => c.exameId == e.complementoDoExame);
                            
                            e.preco = examePai[0].exameDePerfil && !examePai[0].exameDeFaturamento
                                ? 0
                                : examePai[0].exameDePerfil && examePai[0].exameDeFaturamento 
                                    ? exame.preco
                                    : exame.preco;

                            e.precoOriginal = e.preco
                            e.preco = this.precoExameCalculadoComQuantidade(e)
                            e.precoTotalExame = e.preco

                        }else {
                            e.preco = exame.preco 
                            e.precoOriginal = e.preco
                            e.preco = this.precoExameCalculadoComQuantidade(e)
                            e.precoTotalExame = e.preco

                        }

                        if(e.exameDesconto != null && e.exameDesconto != "") {
                            e.preco -= parseFloat(e.exameDesconto);
                        }

                        e.ordemExame = exame.ordemExame;
                        e.regraExame = exame.regraExame;
                        exame.contabilizado = true;
                    });

                

                    this.totalExames = this.totalExamesCalc();
                    this.$forceUpdate();
                })
                

            },
            
            mudouItemTab() {
                this.limparSelecionados()
            }, 
            mostrarIncluindoExame() {
                this.isIncluindoExame = true
                if (this.listaExames == null) {
                    this.loadListaExames()
                    this.loadGrupoExames()
                }
                this.$nextTick(() => {
                    this.$refs.procurarExameTextoInput.focus()
                })
            },
            incluirGrupoExames(grupo) {                
                grupo.itens.forEach(x => {
                    this.incluirExame(x)
                })
            },
            async incluirExameOutraDespesas(exameSelecionado) {
                this.adicionarOutrasDespesas = exameSelecionado[0].exameId;
                await this.loadGrupoExames(true);
                const gruposComExameSelecionado = this.grupoExames.filter(x => x.exames.some(exame => exame.exameId === exameSelecionado[0].exameId) && 
                                                                        x.convenios.some(convenio => convenio.convenioId === this.guia.convenioId));
                this.isIncluindoExame = true;
                this.showIncluirExame = false;

                if (gruposComExameSelecionado.length == 1){
                    this.incluirGrupoExames(gruposComExameSelecionado[0]);
                }
            },
            incluirExame(exame) {
                if(exame.bloqueado){
                    return;
                }
                
                if(this.adicionarOutrasDespesas != 0 && this.adicionarOutrasDespesas != null){
                    exame.complementoDoExame = this.adicionarOutrasDespesas
                }

                const dataDeSolicitacao = new Date();
                let msgDiasRealizacao = "";

                exame.listDiasRealizacao?.forEach(dia => {
                    switch (dia) {
                        case 0:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.DOMINGO')}&nbsp;&nbsp;`;
                            break;
                        case 1:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.SEGUNDA')}&nbsp;&nbsp;`;
                            break;
                        case 2:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.TERCA')}&nbsp;&nbsp;`;
                            break;
                        case 3:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.QUARTA')}&nbsp;&nbsp;`;
                            break;
                        case 4:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.QUINTA')}&nbsp;&nbsp;`;
                            break;
                        case 5:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.SEXTA')}&nbsp;&nbsp;`;
                            break;
                        case 6:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.SABADO')}&nbsp;&nbsp;`;
                            break;
                        default:
                            throw new Error('Index out of range');
                    }
                });

                const id = exame.id ? exame.id : exame.exameId;

                if(this.exames.filter(x => x.exameId == id && x.exameDePerfil).length > 0){
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIAATENDIMENTO.BLOQUEIAEXAMEPERFIL'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMBLOQUEIOPERFIL'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                }
                else if(this.exames.filter(x => x.exameId == id && !exame.bloqueioExameRepetido).length > 0 && 
                    (exame.genero == null || exame.genero == this.guia.pacienteSexo)){
                    if(this.isInRole('incluir-exame-dias-realizacao')){
                        this.$buefy.dialog.confirm({
                            title: this.$t('GUIAATENDIMENTO.EXAMEJAINCLUIDO'),
                            message: this.$t('GUIAATENDIMENTO.CONFIRMAINCLUSAO') + exame.apelido + '</b> ?',
                            confirmText: this.$t('SISTEMA.CONFIRMAR'),
                            type: 'is-info',
                            hasIcon: true,
                            cancelText: this.$t('GUIAATENDIMENTO.CANCELAR'),                                               
                            onConfirm: () => {                                
                                if (exame.setorNotificarExames && !exame.listDiasRealizacao.includes(dataDeSolicitacao.getDay())){
                                    this.$buefy.dialog.confirm({
                                        title: this.$t('GUIAATENDIMENTO.NOTIFICAREXAME'),
                                        message: this.$t('GUIAATENDIMENTO.DIASDISPONIVEIS') + `<b>${msgDiasRealizacao}</b>` + '</br></br>' + this.$t('GUIAATENDIMENTO.CONFIRMAINCLUSAO') + exame.apelido + '</b> ?',
                                        confirmText: this.$t('SISTEMA.CONFIRMAR'),
                                        type: 'is-info',
                                        hasIcon: true,
                                        cancelText: this.$t('GUIAATENDIMENTO.CANCELAR'),                                               
                                        onConfirm: () => {
                                            this.confirmaInclusaoExame(exame);
                                        }
                                    });
                                }else{
                                    this.confirmaInclusaoExame(exame);
                                }
                            }
                        });
                    }
                    else{
                        this.$buefy.dialog.confirm({
                            title: this.$t('GUIAATENDIMENTO.EXAMEJAINCLUIDO'),
                            message: this.$t('GUIAATENDIMENTO.CONFIRMAINCLUSAO') + exame.apelido + '</b> ?',
                            confirmText: this.$t('SISTEMA.CONFIRMAR'),
                            type: 'is-info',
                            hasIcon: true,
                            cancelText: this.$t('GUIAATENDIMENTO.CANCELAR'),                                               
                            onConfirm: () => {
                                this.confirmaInclusaoExame(exame);
                            }
                        });
                    }
                } else if(this.exames.filter(x => x.exameId == id && exame.bloqueioExameRepetido).length > 0) {
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIAATENDIMENTO.BLOQUEIAEXAMEDUPLICADO'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMDUPLICADO'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    
                    return null
                } else if(exame.genero != null && exame.genero != this.guia.pacienteSexo){
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIAATENDIMENTO.BLOQUEIOGENERO'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMBLOQUEIOGENERO'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    return null
                } 
                //CASO TENHA PERMISSÃO PARA INCLUSÃO
                else if (exame.setorNotificarExames && !exame.listDiasRealizacao.includes(dataDeSolicitacao.getDay()) && this.isInRole('incluir-exame-dias-realizacao')){
                    this.$buefy.dialog.confirm({
                        title: this.$t('GUIAATENDIMENTO.NOTIFICAREXAME'),
                        message: this.$t('GUIAATENDIMENTO.DIASDISPONIVEIS') + `<b>${msgDiasRealizacao}</b>` + '</br></br>' + this.$t('GUIAATENDIMENTO.CONFIRMAINCLUSAO') + exame.apelido + '</b> ?',
                        confirmText: this.$t('SISTEMA.CONFIRMAR'),
                        type: 'is-info',
                        hasIcon: true,
                        cancelText: this.$t('GUIAATENDIMENTO.CANCELAR'),                                               
                        onConfirm: () => {
                            this.confirmaInclusaoExame(exame);
                        }
                    });
                }
                //CASO NÃO TENHA PERMISSÃO PARA INCLUSÃO
                else if (exame.setorNotificarExames && !exame.listDiasRealizacao.includes(dataDeSolicitacao.getDay()) && !this.isInRole('incluir-exame-dias-realizacao')){
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIAATENDIMENTO.NOTIFICAREXAME'),
                        message: this.$t('GUIAATENDIMENTO.DIASDISPONIVEIS') + `<b>${msgDiasRealizacao}</b>`,
                        type: 'is-warning',
                        hasIcon: true
                    });
                }
                else {
                    this.confirmaInclusaoExame(exame);
                }

                this.$refs.procurarExameTextoInput.focus();
            },
            confirmaInclusaoExame(exame) {
                const id = exame.id ? exame.id : exame.exameId;

                let localUrgente = false;
                
                const local = this.locais.filter(x => x.id == this.guia.localDeOrigemId)[0];
                if(local){
                    localUrgente = local.urgente;
                }
                this.exames.push({
                    item: 0,
                    exameId: id,
                    exameApelido: exame.apelido,
                    exameGenero: exame.genero,
                    exameNome: exame.nome,
                    exameSetorId: exame.setorId,
                    exameSetorNome: exame.setorNome,
                    exameSetorExamesDeImagem: exame.setorExamesDeImagem,
                    exameSetorNotificarExames: exame.setorNotificarExames,
                    recipienteId: exame.recipienteId,
                    dataDeSolicitacao: new Date(),
                    materialEntregue: (exame.exameDeFaturamento || exame.setorExamesDeImagem || exame.exameDeOutrasDespesas || exame.exameDePerfil) ? false : localStorage.materialEntregue == "true", // localstorage armazena em string
                    urgente: localUrgente == true ? true : localStorage.caraterUrgencia == "true", // localstorage armazena em string
                    informarMedicamento: exame.informarMedicamento,
                    informarHipoteseDiagnostica: exame.informarHipoteseDiagnostica,
                    informacoesPaciente: exame.informacoesPaciente,
                    motivoIds: exame.motivosIds,
                    minimoJejum: exame.minimoJejum,
                    informarPeso: exame.informarPeso,
                    informarAltura: exame.informarAltura,
                    informarDUM: exame.informarDUM,
                    requerAutorizacao : exame.requerAutorizacao,
                    requerSenha: exame.requerSenha,
                    exameDeFaturamento : exame.exameDeFaturamento,
                    exameDePerfil: exame.exameDePerfil,
                    exameDeOutrasDespesas : exame.exameDeOutrasDespesas,
                    quantidadeUs : exame.quantidadeUS,
                    complementoDoExame: exame.complementoDoExame ? exame.complementoDoExame : null,
                    exameDesconto: null,
                    grupoCodigo: exame.grupoCodigo ? exame.grupoCodigo : 0,
                    observacao: exame.grupoCodigo ? exame.quantidadeItemDespesa : null,
                    exameUrgente: exame.urgente
                })
                this.activeItemTab = 0
                this.procurarExameTexto = ''
                this.procurarMaterialTexto = ''
                this.procurarConservanteTexto = ''
                let exaPreco = null;

                if(this.exames.filter(x => x.exameDePerfil).length > 0) {
                    this.exames = this.exames.map(exa => {
                        exaPreco = 0;
                        if(exa.preco == null || exa.preco == undefined) {
                            exa.preco = 0;
                        } 

                        return exa;
                    });

                    this.loadPrecosExames();
                }
                
                //busca se tem exames complementares
                this.$http.get('/api/atendimento/ExamesComplementares?exameId=' + id + '&convenioId=' + this.guia.convenioId + '&recipienteIdPai=' + exame.recipienteId)
                    .then(res => res.data)
                    .then((exames) => {
                        if(exames != null && exames.length > 0){          
                            exames.forEach(exameComplementar => {    
                                let idComplementar = exame.id ? exameComplementar.id : exameComplementar.exameId;
                                
                                if(idComplementar == null || idComplementar == undefined || idComplementar == 0){
                                    idComplementar = exameComplementar.id;
                                }

                                if(this.exames.filter(x => x.exameId == idComplementar).length > 0){
                                    this.$buefy.dialog.alert({
                                        title: this.$t('GUIAATENDIMENTO.INCLUSAOEXAMES'),
                                        message: this.$t('GUIAATENDIMENTO.ENCONTRADO') + exameComplementar.apelido + this.$t('GUIAATENDIMENTO.NESTAGUIA'),
                                        type: 'is-info',
                                        hasIcon: true
                                    });
                                }

                                if(exameComplementar.genero != null && exameComplementar.genero != this.guia.pacienteSexo){
                                    this.$buefy.dialog.alert({
                                        title: this.$t('GUIAATENDIMENTO.BLOQUEIOGENERO'),
                                        message: this.$t('GUIAATENDIMENTO.EXAMECOMPLEMENTAR') + exameComplementar.apelido + this.$t('GUIAATENDIMENTO.NAOPODE'),
                                        type: 'is-warning',
                                        hasIcon: true
                                    });
                                }
                                else {
                                    this.exames.push({
                                        item: 0,
                                        exameId: idComplementar,
                                        exameApelido: exameComplementar.apelido,
                                        exameNome: exameComplementar.nome,
                                        exameGenero: exameComplementar.genero,
                                        exameSetorId: exameComplementar.setorId,
                                        recipienteId: exameComplementar.recipienteId,
                                        dataDeSolicitacao: new Date(),
                                        materialEntregue: exameComplementar.setorExamesDeImagem || exameComplementar.exameDeFaturamento || exameComplementar.exameDePerfil ? false : localStorage.materialEntregue == "true", // localstorage armazena em string
                                        urgente: localUrgente == true && (!exameComplementar.exameDeFaturamento && !exameComplementar.exameDePerfil) ? true : localStorage.caraterUrgencia == "true", // localstorage armazena em string
                                        informarMedicamento: exameComplementar.informarMedicamento,
                                        informarHipoteseDiagnostica: exameComplementar.informarHipoteseDiagnostica,
                                        informacoesPaciente: exameComplementar.informacoesPaciente,
                                        informarPeso: exameComplementar.informarPeso,
                                        informarAltura: exameComplementar.informarAltura,
                                        informarDUM: exameComplementar.informarDUM,
                                        complementoDoExame: id,
                                        minimoJejum: exameComplementar.minimoJejum,
                                        exameDeFaturamento: exameComplementar.exameDeFaturamento,
                                        exameDePerfil: exameComplementar.exameDePerfil,
                                        quantidadeUs : exameComplementar.quantidadeUs,
                                        preco: exaPreco ?? null,
                                        exameDesconto: null,
                                    })
                                }
                            });  
                        }

                    })
            },
            excluirExame({index, guiaexame}) {
                if(this.$route.params.agendar && this.$route.params.agendar.length > 0 &&
                   this.exames[index].agendaEvento && this.exames[index].agendaEvento > 0){
                    this.$route.params.agendar = this.$route.params.agendar.map(a => {
                        if (a.evento.id === this.exames[index].agendaEvento) {
                            a.examesIds = a.examesIds.filter(id => id !== this.exames[index].exameId);
                        }
                        return a;
                    }).filter(a => a.examesIds.length > 0);
                }

                this.exames.splice(index, 1)

                if(guiaexame.complementoDoExame != null && guiaexame.complementoDoExame > 0) {
                    let count = 0;
                    //remove exame pai
                    this.exames.filter(exames => exames.exameId == guiaexame.complementoDoExame).forEach( exame => {
                        if((count == 0 && exame.exameDePerfil && !exame.exameDeFaturamento) || (count == 0 && exame.exameDePerfil && exame.exameDeFaturamento)){
                            const exameIndex = this.getExameIndex(exame.exameId);

                            this.exames.splice(exameIndex, 1);
                            count += 1;

                        }
                    });

                    //remove vinculo do exame filho com o pai(removido anteriormente)
                    this.exames.filter(exames => exames.complementoDoExame == guiaexame.complementoDoExame).forEach( exame => {
                        exame.complementoDoExame = null;                        
                    });

                }else {
                    this.exames.filter(exames => exames.complementoDoExame == guiaexame.exameId).forEach( exame => {
                        exame.complementoDoExame = null;
                        
                    });
                }
                this.loadPrecosExames();                        
            },
            getExameIndex(exameId) {
                let exameIndex = 0;

                const result = this.exames.filter((exa) => {
                    return exa.exameId == exameId;
                });
                    
                for(const exame of result){
                    exameIndex = this.exames.indexOf(exame);    
                }

                return exameIndex;
            },
            selecionarTodosExames() {
                if (this.activeItemTab == 0) {
                    this.exames.forEach(x => {
                        this.selecionarExame(x)
                    })
                }
            },
            selecionarExame(exame) {
                if (this.exameSelecionado == null) {
                    this.exameSelecionado = [exame]
                } else {
                    if (this.exameSelecionado.includes(exame)) {
                        this.exameSelecionado = this.exameSelecionado.filter(function (x) { return x != exame })
                    } else {
                        this.exameSelecionado.push(exame)
                    }
                }
            },
            selecionarTodasAmostras() {
                if (this.activeItemTab == 1) {
                    this.amostras.forEach(x => {
                        this.selecionarAmostra(x)
                    })
                }
            },
            selecionarAmostra(amostra) {
                if (this.amostraSelecionada == null) {
                    this.amostraSelecionada = [amostra]
                } else {
                    if (this.amostraSelecionada.includes(amostra)) {
                        this.amostraSelecionada = this.amostraSelecionada.filter(function (x) { return x != amostra })
                    } else {
                        this.amostraSelecionada.push(amostra)
                    }
                }
            },

            selecionarTodosEstudos() {
                if (this.activeItemTab == 2) {
                    this.estudos.forEach(x => {
                        this.selecionarEstudo(x)
                    })
                }
            },
            selecionarEstudo(estudo) {
                this.estudoSelecionado = [];
                if (this.estudoSelecionado == null) {
                    this.estudoSelecionado = [estudo]
                } else {
                    if (this.estudoSelecionado.includes(estudo)) {
                        this.estudoSelecionado = this.estudoSelecionado.filter(function(x) { return x != estudo })
                    } else {
                        this.estudoSelecionado.push(estudo)
                    }
                }
            },

            abrirAlterarDataDeSolicitacao() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlterarDataDeSolicitacao,
                    props: {
                        selecionado: this.exameSelecionado
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            abrirAlterarDataDeColeta() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlterarDataDeColeta,
                    props: {
                        selecionado: this.exameSelecionado
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            alterarCodigoIntegracaoExame(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlterarCodigoIntegracaoExame,
                    props: {
                        selecionado: this.exameSelecionado
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            abrirCancelamentoColetaAmostra() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalCancelandoColetaAmostra,
                    props: {
                        guia: this.guia,
                        selecionada: this.amostraSelecionada
                    },
                    events: {
                        loadGuia: this.loadGuia
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            abrirCancelamentoAmostra() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalCancelandoAmostra,
                    props: {
                        guia: this.guia,
                        selecionada: this.amostraSelecionada
                    },
                    events: {
                        loadGuia: this.loadGuia
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            abrirIncidenciaAmostra() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalIncidenciaAmostra,
                    props: {
                        guia: this.guia,
                        selecionada: this.amostraSelecionada,
                        titulo: this.$t('GUIA.AMOSTRAS'),
                    },
                    events: {
                        loadGuia: this.loadGuia
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            abrirCancelamentoExame() {
                const exameDePerfilOuComplementar = [];
                const examesSelecionados = [];
                
                this.exameSelecionado.forEach(exame => {
                    examesSelecionados.push(exame);
                });
                    
                this.exameSelecionado.forEach(exame => {
                    if (exame.complementoDoExame != null && exame.complementoDoExame > 0 || exame.exameDePerfil) {
                        exameDePerfilOuComplementar.push(exame);
                    }
                });

                if (exameDePerfilOuComplementar.length > 0) {
                    this.$buefy.dialog.confirm({
                        title: this.$t('GUIAATENDIMENTO.CANCELAREXAMEDEPERFIL'),
                        message: this.$t('GUIAATENDIMENTO.MSGCANCELAREXAMEDEPERFIL'),
                        type: 'is-warning',
                        confirmText: this.$t('SISTEMA.CONFIRMAR'),
                        cancelText: this.$t('GUIAATENDIMENTO.CANCELAR'),                                               
                        onConfirm: () => {
                            this.$buefy.modal.open({
                                parent: this,
                                component: modalCancelandoExame,
                                props: {
                                guia: this.guia,
                                selecionado: this.exameSelecionado
                                },
                                events: {
                                loadGuia: this.loadGuia
                                },
                                hasModalCard: true,
                                trapFocus: true
                            });
                        },
                    });
                } else {
                    this.$buefy.modal.open({
                        parent: this,
                        component: modalCancelandoExame,
                        props: {
                            guia: this.guia,
                            selecionado: this.exameSelecionado
                        },
                        events: {
                            loadGuia: this.loadGuia
                        },
                        hasModalCard: true,
                        trapFocus: true
                    })
                }
            },
            abrirModalCancelamentoDigitacaoExame()
            {
                console.log('Preparando para abrir modal de exames candidatos para cancelar digitação')
                const examesSelecionados = [];
                this.exameSelecionado.forEach(exame => {
                    examesSelecionados.push(exame);
                });
                console.log('Exames candidatos para cancelar digitação', examesSelecionados)
                this.$buefy.modal.open({
                    parent: this,
                    component: modalCancelandoDigitacaoExame,
                    props: {
                        guia: this.guia,
                        selecionado: this.exameSelecionado
                    },
                    events: {
                        loadGuia: this.loadGuia
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            desvincularExame() {

                this.$buefy.dialog.confirm({
                    title: this.$t('GUIAATENDIMENTO.DESVINCULAREXAME'),
                    message: this.$t('GUIAATENDIMENTO.CONFIRMDESVINCULAR') + this.exameSelecionado.map(x => '<span class="tag is-info">' + x.exameApelido + '</span>'),
                    confirmText: this.$t('SISTEMA.CONFIRMAR'),
                    cancelText: this.$t('GUIAATENDIMENTO.CANCELAR'),                                    
                    hasIcon: true,
                    onConfirm: () => {
                            this.$http.post('/api/atendimento/DesvincularExames', {
                                id: this.guia.id,
                                item: this.exameSelecionado.map(x => x.item)
                            })
                            .then((res) => {
                                if (res.body.erro) {
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: res.body.erro,
                                        type: 'is-danger'
                                    })
                                } else {
                                    this.exameSelecionado.forEach(x => {
                                        x.copiaDe = null;
                                    });
                                }
                            })
                    }
                })

                                   
            },            

            confirmarBloqueioExame() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalBloqueioExame,
                    props: {
                        guia: this.guia,
                        selecionado: this.exameSelecionado,                        
                        result:null
                    },
                    hasModalCard: true,
                    events: {
                        loadGuia: this.loadGuia
                    },
                    trapFocus: true,
                    fullScreen: true
                })
            },
            confirmarDesbloqueioExame() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalDesbloqueioExame,
                    props: {
                        guia: this.guia,
                        selecionado: this.exameSelecionado
                    },
                    hasModalCard: true,
                    events: {
                        loadGuia: this.loadGuia
                    },
                    trapFocus: true,
                    fullScreen: true
                })
            },

            confirmarRepeticaoExame() {
                this.$buefy.dialog.confirm({
                    title: this.$t('GUIAATENDIMENTO.REPETIREXAME'),
                    message: this.$t('GUIAATENDIMENTO.CONFIRMREPETIR') + this.exameSelecionado.map(x => '<span class="tag is-info">' + x.exameApelido + '</span>'),
                    confirmText: this.$t('SISTEMA.CONFIRMAR'),
                    cancelText: this.$t('GUIAATENDIMENTO.CANCELAR'),                                    
                    hasIcon: true,
                    onConfirm: () => {
                        this.$http.post('/api/atendimento/exameRepetido', {
                            id: this.guia.id,
                            item: this.exameSelecionado.map(x => x.item)
                        })
                            .then((res) => {
                                if (res.body.erro) {
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: res.body.erro,
                                        type: 'is-danger'
                                    })
                                } else {
                                    this.loadGuia()
                                }
                            })
                    }
                })
            },

            confirmarAlertaExame() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlertaExame,
                    props: {
                        guia: this.guia,
                        selecionado: this.exameSelecionado
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })
            },

            enviarAmostraIntegracao() {
                this.amostraSelecionada.forEach(x => {
                    
                    this.$http.get('/integracao/enviar?mensagem=EnviarAmostra&valor=' + x.codigoDeBarras + '&integradorId=' + x.integradorId)
                        .then((res) => {
                            if (res.body.erro) {
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: res.body.erro,
                                    type: 'is-danger'
                                })
                            } else {
                                this.$buefy.snackbar.open({
                                    duration: 2000,
                                    message: this.$t('GUIAATENDIMENTO.AMOSTRAENVIADA') + x.codigoDeBarras,
                                    type: 'is-success',
                                    position: 'is-top-right',
                                    queue: false
                                })
                            }
                        })
                })
            },
            abrirSolicitarNovaAmostra() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalSolicitarNovaAmostra,
                    props: {
                        guia: this.guia,
                        selecionada: this.amostraSelecionada
                    },
                    events: {
                        loadGuia: this.loadGuia
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })
            },
            reimprimirAmostraIntegracao() {
                const integradores = [];
                this.amostraSelecionada.forEach(x => {
                    if(integradores.indexOf(x.integradorId) == -1)
                        integradores.push(x.integradorId)
                });
                
                integradores.forEach(integrador => {
                    this.$http.get(`/integracao/enviar?mensagem=EnviarReimpressao&valor=${this.guia.id}&integradorId=${integrador}&informacaocomplementar=${this.amostraSelecionada.map(amostra => amostra.codigoDeBarras).join(',')}`)
                        .then((res) => {
                            if (res.body.erro) {
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: res.body.erro,
                                    type: 'is-danger'
                                })
                            } else {
                                this.$buefy.snackbar.open({
                                    duration: 2000,
                                    message: this.$t('GUIAATENDIMENTO.REIMPRIMIRAMOSTRAS') + this.amostraSelecionada.map(amostra => amostra.codigoDeBarras).join(','),
                                    type: 'is-success',
                                    position: 'is-top-right',
                                    queue: false
                                })
                            }
                        })
                })
            },

            anexarEstudo() {
                let exames = "";
                this.exameSelecionado.forEach(x => {
                    exames += this.guia.id + '_' + x.item
                })
                
                 this.$buefy.modal.open({
                    parent: this,
                    component: modalAnexoVue,
                    props: {
                        guiasExames: exames,
                        guiaId: this.guia.id
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },

            confirmarEntregaDeResultados() {
                this.$buefy.dialog.prompt({
                    title: this.$t('GUIAATENDIMENTO.REGISTRARRESULTADOS'),
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMREGISTRARRESULTADOS') + this.exameSelecionado.map(x => '<span class="tag is-info">' + x.exameApelido + '</span>'),
                    inputAttrs: {
                        type: 'text',
                        placeholder: 'Para:'
                    },                    
                    confirmText: this.$t('SISTEMA.CONFIRMAR'),
                    cancelText: this.$t('GUIAATENDIMENTO.CANCELAR'),
                    trapFocus: true,
                    onConfirm: (res) => {
                        this.$http.post('/api/atendimento/ResultadoEntregue', {
                            id: this.guia.id,
                            item: this.exameSelecionado.map(x => x.item),
                            info: res
                        })
                            .then((res) => {
                                if (res.body.erro) {
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: res.body.erro,
                                        type: 'is-danger'
                                    })
                                } else {
                                    this.loadGuia()
                                }
                            })
                    }
                })
            },

            abrirInfoExame() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalInfoExame,
                    props: {
                        guia: this.guia,
                        selecionado: this.exameSelecionado
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })
            },

            preencherVariaveis(comprovante) {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalComprovanteVariavel,
                    props: {
                        guia: this.guia,
                        comprovante: comprovante                        
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: false
                })
            },            

            abrirInfoAmostra() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalInfoAmostra,
                    props: {
                        guia: this.guia,
                        selecionada: this.amostraSelecionada
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })
            },

            abrirInfoEstudo() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalInfoEstudo,
                    props: {
                        guia: this.guia,
                        selecionado: this.estudoSelecionado
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })
            },

            imprimirEtiquetaAmostraSelecionada() {                
                console.log("Invoke EtiquetaAmostra com argumentos:");
                console.log("list id: ", this.amostraSelecionada.filter(x=>x.amostraTipo != 4).map(x => x.id));
                console.log("localStorage.etiquetaAtual (é o modelo da etiqueta): ", localStorage.etiquetaAtual);
                console.log("portal: ", false)
                this.$notificacaoHub.invoke("EtiquetaAmostra",
                    this.amostraSelecionada.filter(x=>x.amostraTipo != 4).map(x => x.id),
                    localStorage.etiquetaAtual,false)
                    .then(() => {
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: this.$t('GUIAATENDIMENTO.IMPRESSAOSOLICITADA') + this.amostraSelecionada.map(x => x.id),
                            type: 'is-success',
                            position: 'is-top-right',
                            queue: false
                        })
                    })
                    .catch(e => {
                        console.error("Invoke falhou: ", e);
                        console.error("Detalhes do erro: ");
                        console.error("Mensagem de erro: ", e.message);
                        console.error("Stack trace erro:", e.stack);
                        return console.error(e.toString());
                    })
            },

            imprimirTodasEtiquetas() {
                console.log("Invoke EtiquetaAmostraGuia com argumentos:");
                console.log("list id: ", [this.guia.id]);
                console.log("localStorage.etiquetaAtual (é o modelo da etiqueta): ", localStorage.etiquetaAtual);
                console.log("portal: ", false)
                this.$notificacaoHub.invoke("EtiquetaAmostraGuia",
                    [this.guia.id],
                    localStorage.etiquetaAtual, false)
                    .then(() => {
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: this.$t('GUIAATENDIMENTO.IMPRESSAOSOLICITADAGUIA') + this.guia.id,
                            type: 'is-success',
                            position: 'is-top-right',
                            queue: false
                        })
                    })
                    .catch(e => {
                        console.error("Invoke falhou: ", e);
                        console.error("Detalhes do erro: ");
                        console.error("Mensagem de erro: ", e.message);
                        console.error("Stack trace erro:", e.stack);
                        return console.error(e.toString());
                    })
            },

            confirmarAlterarCodigoDeBarras() {
                this.$buefy.dialog.prompt({
                    title: this.$t('GUIAATENDIMENTO.ALTERACODIGO'),
                    message: 'Amostra:<br/>' + this.amostraSelecionada.map(x => '<span class="tag is-info">' + x.codigoDeBarras + '</span>'),
                    inputAttrs: {
                        type: 'text',
                        placeholder: this.$t('GUIAATENDIMENTO.NOVOCODIGO'),
                    },
                    trapFocus: true,
                    confirmText: this.$t('SISTEMA.CONFIRMAR'),
                    cancelText: this.$t('GUIAATENDIMENTO.CANCELAR'),                    
                    onConfirm: (res) => {
                        this.$http.post('/api/atendimento/AlteraCodigoDeBarrasAmostra', {
                            amostraId: this.amostraSelecionada[0].id,
                            novoCodigoDeBarras: res
                        })
                            .then((res) => {
                                if (res.body.erro) {
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: res.body.erro,
                                        type: 'is-danger'
                                    })
                                } else {
                                    this.loadGuia()
                                }
                            })
                    }
                })
            },

            mudouObsTab(value) {                
                if (value == 'respostas') {
                    if (this.respostasPesquisas == null || this.respostasPesquisas.length == 0) {
                        this.loadRespostasPesquisas()
                    }
                } else if (value == 'historicoAlteracoes') {
                    if (this.historicoAlteracoes == null) {
                        this.loadHistoricoAlteracoes()
                    }
                } else if (value == 'vinculos') {
                    if (this.guiasFilhas == null) {
                        this.loadGuiasFilhas()
                    }
                } else if (value == 'anexos') {
                    if (this.anexos == null) {
                        this.loadAnexos()
                    }
                } else if (value == 'desconto') {
                    if (this.listaCondicoesPagamento == null) {
                        this.loadListaCondicoesPagamento();                        
                    }
                                     
                    this.loadPrecosExames();
                    this.totalExames = this.totalExamesCalc();
                }
            },
            getMedicamentos(text) {
                if(text){                    
                    const textReplaced = text.replace(/[^\w\s]/gi, '').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\\]/gi, '').trim();	                        

                    if(textReplaced){               
                        this.$http.get('/api/search/Medicamentos', { params: { nome: textReplaced } })
                            .then(res => {
                                this.filteredMedicamentos = res.data
                            });
                    }
                }
            },            
            getCid10() {
                this.cid10loading = true
                this.cid10message = null;
                if(this.guia.cid10){
                    this.$http.get('/api/search/Cid10?nome=' + this.guia.cid10 )
                        .then(res => {
                            this.cid10type = "is-success"
                            this.cid10descricao = res.data[0].descricao
                        })
                        .catch(() => {
                            this.cid10type = "is-danger"
                            this.cid10descricao = null
                        })
                        .finally(() => {
                            this.cid10loading = false
                    })
                }
            },
            escolheuConvenio(val) {        

                this.pagamentoGuia = (val.data.convenioTipoParticular && !val.data.reembolso) || (val.data.convenioCoparticipacao && this.guia.id == 0); 

                this.guia.convenioTipoExameCodigoId =  val.data.exameCodigoTipoId;    
                this.guia.convenioTipoParticular =  val.data.convenioTipoParticular;        
                this.guia.convenioCoparticipacao =  val.data.convenioCoparticipacao;  
                this.guia.inscricaoObrigatoria = val.data.inscricaoObrigatoria;
                this.guia.convenioControleDeGuiasSadt = val.data.convenioControleDeGuiasSadt;
                
                this.observacoesConvenio = val.data.observacoes;
                
                if (this.guia.id == 0) {
                    if (val.data.exibeObsGuia) {
                        this.guia.observacoes = val.data.observacoes;
                    } else {
                        this.guia.observacoes = "";
                    }
                }

                if(this.observacoesInspecao !=null)
                        this.guia.observacoes = this.observacoesInspecao ? this.observacoesInspecao.replaceAll('\\n','\n') : '';                         

                this.guia.convenioCoparticipacao =  val.data.convenioCoparticipacao; 
                
                if(!this.guia.convenioTipoParticular && (this.convenioAtual != this.guia.convenioId )) {
                    this.exames.forEach(x => x.preco = null);
                }  
                
                if(this.isIncluindoExame){
                    this.isIncluindoExame = false;
                }

                if(this.exames.length>0) {
                    this.loadListaExames(false, true);
                }

                this.$http.get(`/Search/convenioId?id=${val.data.id}&unidadeId=${this.guia.unidadeDeCadastroId}&multiplasUnidades=${true}`)
                    .then((res) => {
                        if (res.body.fail == true && this.guia.id == 0) {
                            this.$buefy.dialog.alert({
                            message: this.$t('GUIAATENDIMENTO.CONVENIOERRO'),
                            type: 'is-warning',
                            hasIcon: true
                        });
                            return;
                        }
                    })

            },            
            mudouSadt(event,sadtId) {  
                if(event.added){

                    const exameAlterado = event.added;                                    
                    const exameEncontrado = this.exames.find(x => x.exameId == exameAlterado.element.id 
                    && x.item == exameAlterado.element.item  );
                    
                    if(exameEncontrado){                                                
                        exameEncontrado.sadtId = sadtId;                        
                    }                        
                                        
                }
                    
            },
            incluirSadt() {
                this.indexSadt += 1;

                this.sadts.push({                    
                    id: 0,
                    numero: null,
                    autorizacao: null,
                    autorizacaoSenha: null,
                    autorizacaoSenhaValidade: null,
                    index: this.indexSadt,
                    exames: []
                })
            },
            totalExamesCalc(){
                if(this.exames == null){
                    return 0.0;
                }
                else {
                    let total = 0.0;
                    this.exames.filter(this.config.naoFaturarExamesVinculados ? x => !x.cancelado && !x.vinculado : x => !x.cancelado).forEach(exame => {
                        const desconto = exame.exameDesconto ?? 0;
                        total += exame.preco + parseFloat(desconto);
                    });
                    return parseFloat(total).toFixed(2);
                }
            },
            vincularGuia() {
                this.$http.post('/api/atendimento/vincularGuia', {
                    guiaOriginal: this.guia.id,
                    guiaVincular: this.vincularGuiaId
                })
                    .then((res) => {
                        if (res.body.erro) {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: res.body.erro,
                                type: 'is-danger'
                            })
                        } else {
                            this.loadGuia()
                        }
                    })
            },
            exibeNoLaudo(item){
                const anexo = {
                    id: item.id,
                    exibirNoLaudo: false
                };

                if (item.exibirNoLaudo === true) {
                    this.$set(anexo, 'exibirNoLaudo', true);
                }
                
                this.$http.post('/api/atendimento/anexos', anexo);
            },
            removerAnexo(id) {
                const anexo = this.anexos.filter(x => x.id == id)[0]
                this.$buefy.dialog.confirm({
                    title: this.$t('ANEXOS.EXCLUIRANEXO'),
                    message: this.$t('ANEXOS.CONFIRMAEXCLUSAO') + anexo.anexo.replace(/^.*[\\/]/, '') + '</b> ?',
                    confirmText: this.$t('SISTEMA.CONFIRMAR'),                    
                    cancelText: this.$t('SISTEMA.CANCELAR'),                      
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {

                        const removerAnexo = {
                            tipo: "guia",
                            anexoId: id
                        }

                        this.$http.post('/api/atendimento/excluirAnexoLaudo', removerAnexo)
                            .then((res) => {
                                if (res.body.erro) {
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: res.body.erro,
                                        type: 'is-danger'
                                    })
                                } else {
                                    this.loadAnexos()
                                }
                            })
                    }
                })
            },
            incluirAnexo(fileList) {
                const formData = new FormData()
                formData.append('id', this.guia.id);
                formData.append('type', 'guia');
                
                if (fileList.length == 0) {
                    this.uploadKey++;
                    this.$buefy.toast.open({
                        duration: 3000,
                        message: this.$t('ATENDIMENTO.EXTENSAONAOSUPORTADA'),
                        type: 'is-danger'
                    });
                    this.fileList = [];
                    return;
                }
                if(this.uploadKey > 0){
                    this.uploadKey = 0;
                }
                
                Array
                    .from(Array(fileList.length).keys())
                    .map(x => {                        
                        formData.append('anexo', fileList[x], fileList[x].name);
                    });

                this.uploadingAnexo = 0
                this.$http.post('/api/atendimento/anexoLaudo', formData, {
                    progress(e) {
                        if (e.lengthComputable) {
                            this.uploadingAnexo = (e.loaded / e.total * 100)
                        }
                    }
                }).then(response => {                    
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: response.data.sucesso,
                        type: 'is-success'
                    })
                    this.loadAnexos()
                    this.loadHistoricoAlteracoes()
                }, error => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: error.body.message ?? "Erro inesperado: Arquivo corrompido ou muito grande",
                        type: 'is-danger'
                    })
                })
            },
            comprovanteGuia(guia, modelo){
                if(this.exameSelecionado != null && this.exameSelecionado.length > 0)
                {
                    const itensExamesSelecionados = [];
                    this.exameSelecionado.forEach(exame => {
                        itensExamesSelecionados.push(exame.item);
                    });
                    const itens = '&sel=' + itensExamesSelecionados.join(',');

                    
                    window.open('/Guia/Comprovante?id=' + guia + (modelo != null ? '&modelo=' + modelo : '') + itens);
                }
                else
                {
                    window.open('/Guia/Comprovante?id=' + guia + (modelo != null ? '&modelo=' + modelo : ''));
                }
            },
            envioComprovanteEmail(id, modelo, guia) {
                if (!guia.pacienteEmail) {
                    return this.$buefy.toast.open({
                        duration: 10000,
                        message: "Necessário e-mail do paciente",
                        type: 'is-danger',
                        queue: false
                    });
                }

                let url = "/api/atendimento/envioComprovanteEmail?id=" + id + (modelo != null ? '&modelo=' + modelo : '');
                let itens = "";

                if (this.exameSelecionado != null && this.exameSelecionado.length > 0) {
                    const itensExamesSelecionados = [];
                    this.exameSelecionado.forEach(exame => {
                        itensExamesSelecionados.push(exame.item);
                    });

                    itens = '&sel=' + itensExamesSelecionados.join(',');

                    url += itens;
                }

                this.$http.get(url)
                    .then(data => {
                        if (data.ok) {
                            this.$buefy.toast.open({
                                duration: 10000,
                                message: "Documento enviado com sucesso",
                                type: 'is-success',
                                queue: false
                            });
                        }
                    })
                    .catch(err => {
                        this.$buefy.toast.open({
                            duration: 10000,
                            message: err.body,
                            type: 'is-danger',
                            queue: false
                        })
                    });
            },
            enviaDocumento(id, modelo, guia){
                if(!guia.pacienteCPF){
                    return this.$buefy.toast.open({
                            duration: 10000,
                            message: "Necessário CPF do paciente",
                            type: 'is-danger',
                            queue: false
                    });


                }

                if(!guia.pacienteEmail){
                    return this.$buefy.toast.open({
                            duration: 10000,
                            message: "Necessário e-mail do paciente",
                            type: 'is-danger',
                            queue: false
                    });
                }

                let url = "/api/atendimento/enviarDocumentoParaAssinatura?id=" + id + (modelo != null ? '&modelo=' + modelo : '');
                let itens = "";

                if(this.exameSelecionado != null && this.exameSelecionado.length > 0)
                {
                    const itensExamesSelecionados = [];
                    this.exameSelecionado.forEach(exame => {
                        itensExamesSelecionados.push(exame.item);
                    });

                    itens = '&sel=' + itensExamesSelecionados.join(',');

                    url += itens;
                }

                this.$http.get(url)
                    .then(data => {
                        if (data.ok) {
                            this.$buefy.toast.open({
                                duration: 10000,
                                message: "Documento enviado com sucesso",
                                type: 'is-success',
                                queue: false
                            });
                        }
                    })
                    .catch(err => {
                        this.$buefy.toast.open({
                            duration: 10000,
                            message: err.body,
                            type: 'is-danger',
                            queue: false
                        })
                    });
            },
            imprimeComprovanteGuia(guia, modelo){
                if(this.exameSelecionado != null && this.exameSelecionado.length > 0)
                {
                    const itensExamesSelecionados = [];
                    this.exameSelecionado.forEach(exame => {
                        itensExamesSelecionados.push(exame.item);
                    });
                    const itens = '&sel=' + itensExamesSelecionados.join(',');
                    window.open('/Guia/ImprimeComprovante?id=' + guia + (modelo != null ? '&modelo=' + modelo : '') + itens);
                }
                else
                {
                    window.open('/Guia/ImprimeComprovante?id=' + guia + (modelo != null ? '&modelo=' + modelo : ''));
                }
            },
            visualizarAnexo(anexoId, fileName) {                
                window.open('/api/atendimento/anexoPdf?id=' + this.guia.id + '&anexoId=' + anexoId + '&fileName=' + fileName);
            },
            preVisualizarPdf(modelo){
                const laudoRestrito = this.exames.some(x => x.laudoRestrito);
                const preVisualizacao = true;
                const itensExamesSelecionados = [];

                if(this.exameSelecionado != null && this.exameSelecionado.length > 0)
                {
                    //Traz exames normais selecionados
                    this.exameSelecionado.filter( x => !x.laudoRestrito).forEach(exame => {
                        itensExamesSelecionados.push(exame.item);
                    });
                    
                    //Traz exames digitados que são restritos, que usuario possui permissao
                    this.exameSelecionado.filter( x => this.isInRole('laudo-restrito') && x.laudoRestrito && x.digitado).forEach(exame => {
                        itensExamesSelecionados.push(exame.item);
                    });
                }
                else
                {
                    //Traz exames que estejam digitados e que não estejam assinados
                    this.exames.filter(x=> x.digitado && !x.assinado && !x.laudoRestrito).forEach(exame => {
                        itensExamesSelecionados.push(exame.item);
                    });
                }
                
                const itens = '&sel=' + itensExamesSelecionados.join(',');

                window.open('/Guia/Pdf?id=' + this.guia.id + (modelo != null ? '&modelo=' + modelo : '') + '&laudoRestrito=' + laudoRestrito + itens + '&preVisualizacao=' + preVisualizacao);
            },
            visualizarPdf(modelo){
                const laudoRestrito = this.exames.some(x => x.laudoRestrito);

                if(this.exameSelecionado != null && this.exameSelecionado.length > 0)
                {
                    const itensExamesSelecionados = [];


                    //Traz exames normais selecionados
                    this.exameSelecionado.filter( x => !x.laudoRestrito).forEach(exame => {
                        itensExamesSelecionados.push(exame.item);                        
                    });    
                    
                    //Traz exames que são restritos e que usuario possui permissao
                    this.exameSelecionado.filter( x => this.isInRole('laudo-restrito') && x.laudoRestrito).forEach(exame => {
                        itensExamesSelecionados.push(exame.item);                        
                    });
                                    
                    const itens = '&sel=' + itensExamesSelecionados.join(',');
                    window.open('/Guia/Pdf?id=' + this.guia.id + (modelo != null ? '&modelo=' + modelo : '')  + '&laudoRestrito=' + laudoRestrito + itens);
                }
                else
                {                
                    window.open('/Guia/Pdf?id=' + this.guia.id + (modelo != null ? '&modelo=' + modelo : '') + '&laudoRestrito=' + laudoRestrito);
                }
            },
            imprimirPdf(){                
                if(this.exameSelecionado != null && this.exameSelecionado.length > 0)
                {
                    const itensExamesSelecionados = [];
                    this.exameSelecionado.forEach(exame => {
                        itensExamesSelecionados.push(exame.item);
                    });
                    const itens = itensExamesSelecionados.join(',');
                    window.open('/Home/ImpressaoPdf?guiaExames=' + this.guia.id+'_'+itens + '&descricao=Resultado');
                }
                else
                {
                    window.open('/Home/ImpressaoPdf?guiaExames=' + this.guia.id + '&descricao=Resultado');  
                }              
            },
            digitarResultadosPor(){
                if(this.exameSelecionado != null && this.exameSelecionado.length > 0)
                {
                    if(this.exameSelecionado.filter(x => !x.digitado && !x.bloqueado && !x.cancelado && !x.assinado).length > 0) {
                        const itensExamesSelecionados = [];
                        this.exameSelecionado.forEach(exame => {
                            itensExamesSelecionados.push(exame.item);
                        });
                        const itens = '&sel=' + itensExamesSelecionados.join(',');
                        window.location.href = '/Analise/DigitarResultadosPor?guiaId=' + this.guia.id + '' + itens;
                    }
                    else {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('GUIAATENDIMENTO.NENHUMPENDENTE'),
                            type: 'is-warning'
                        })
                    }
                }
                else
                {
                    window.location.href = '/Analise/DigitarResultadosPor?guiaId=' + this.guia.id;  
                } 
            },
            redirecionarPagamentoGuia() {
                if (this.agendamentoDashboard != "true") {
                    this.pagamentoGuia = true
                    if (this.exames.every(x => x.faturado || x.cancelado || (x.copiaDe != null && this.config.naoFaturarExamesVinculados))) {
                        this.salvarGuia()
                    } else {
                        this.tabs = 'desconto'
                        this.mudouObsTab('desconto')
                        this.$refs.inputDescontoAcordado.focus()
                    }
                }
            },
            emailMedico(){
                this.$buefy.dialog.prompt({
                    title: this.$t('GUIAATENDIMENTO.EMAILMEDICO'),
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMEMAILMEDICO'),
                    inputAttrs: {
                        type: 'text',
                        placeholder: this.$t('GUIAATENDIMENTO.EMAIL'),
                        value: this.guia.medicoEmail
                    }, 
                    confirmText: this.$t('SISTEMA.CONFIRMAR'),
                    cancelText: this.$t('GUIAATENDIMENTO.CANCELAR'),
                    trapFocus: true,
                    onConfirm: (res) => {
                        let itens = '';
                        if(this.exameSelecionado != null && this.exameSelecionado.length > 0) {
                            const itensExamesSelecionados = [];
                            this.exameSelecionado.forEach(exame => {
                                itensExamesSelecionados.push(exame.item);
                            });
                            itens = '&sel=' + itensExamesSelecionados.join(',');
                        }

                        this.$http.get('/api/atendimento/PdfEmailMedico?id=' + this.guia.id + '&email=' + res + itens)
                        .then((res) => {
                            if (res.body.erro) {
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: res.body.erro,
                                    type: 'is-danger'
                                })
                            } else {
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: res.body.sucesso,
                                    type: 'is-success'
                                })
                            }
                        })
                    }
                })
            },
            emailPaciente(){
                this.$buefy.dialog.prompt({
                    title: this.$t('GUIAATENDIMENTO.EMAILPACIENTE'),
                    message: this.$t('GUIAATENDIMENTO.EMAILPACIENTEMENSAGEM'),
                    inputAttrs: {
                        type: 'text',
                        placeholder: 'Email',
                        value: (this.guia.responsavelEmail) ? this.guia.responsavelEmail : this.guia.pacienteEmail
                    }, 
                    confirmText: this.$t('SISTEMA.CONFIRMAR'),
                    cancelText: this.$t('GUIAATENDIMENTO.CANCELAR'),
                    trapFocus: true,
                    onConfirm: (res) => {
                        let itens = '';
                        if(this.exameSelecionado != null && this.exameSelecionado.length > 0) {
                            const itensExamesSelecionados = [];
                            this.exameSelecionado.forEach(exame => {
                                itensExamesSelecionados.push(exame.item);
                            });
                            itens = '&sel=' + itensExamesSelecionados.join(',');
                        }

                        this.$http.get('/api/atendimento/PdfEmailPaciente?id=' + this.guia.id + '&email=' + res + itens)
                        .then((res) => {
                            if (res.body.erro) {
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: res.body.erro,
                                    type: 'is-danger'
                                })
                            } else {
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: res.body.sucesso,
                                    type: 'is-success'
                                })
                            }
                        })
                    }
                })
            },
            tissConsultarGuia(guiaId) {
                this.$http.get('/api/atendimento/TissConsultarGuia?id='+guiaId)
                    .then(() => {
                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message: this.$t('GUIAATENDIMENTO.ENVIADOSUCESSO'),
                                    type: 'is-success',
                                    queue: false
                                })
                        
                    })
            },   
            tissCancelarGuia(guiaId) {
                this.$http.get('/api/atendimento/TissCancelarGuia?id='+guiaId)
                    .then(() => {
                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message: this.$t('GUIAATENDIMENTO.ENVIADOSUCESSO'),
                                    type: 'is-success',
                                    queue: false
                                })
                    })
            },     
            abrirAlterarDataUltimaRefeicao() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalCalculoTempoJejum,
                    events: {
                        setarHorasJejum: this.setarHorasJejum
                    },                   
                    hasModalCard: true,
                    trapFocus: true
                })
            } ,
            setarHorasJejum(horas){
                this.guia.horasDeJejum = horas;                
            },
            formataDesconto(){
                if(this.guia.desconto != null && this.guia.desconto != '' && this.guia.desconto != undefined) {
                    if((this.guia.desconto + "").indexOf("%") != -1 && !isNaN(parseFloat((this.guia.desconto + "").replace("%", "").replace(",", ".")))) {
                        this.guia.desconto = (Math.round(parseFloat((this.guia.desconto + "").replace("%", "").replace(",", ".")) * 100,2) / 100) + "%"
                    } else if(!isNaN(parseFloat((this.guia.desconto + "").replace(",", ".")))) {
                        this.guia.desconto = Math.round(parseFloat((this.guia.desconto + "").replace(",", ".")) * 100,2) / 100
                    }
                }
            },    
            totalDescontoPorExame(exameDesconto, exame) {
                if((this.guia.desconto == "" || this.guia.desconto == null) && exameDesconto != null && exameDesconto != '' && exameDesconto != undefined) {
                    if((exameDesconto + "").indexOf("%") != -1 && !isNaN(parseFloat((exameDesconto + "").replace("%", "").replace(",", ".")))) {
                        exame.precoTotalExame = exame.precoTotalExame ?? (parseFloat(exame.preco) + parseFloat(exame.exameDesconto));

                        const descontoPercentual =  (parseFloat(exame.precoTotalExame) * (parseFloat((exameDesconto + "").replace("%", "").replace(",", ".")) / 100))

                        exame.preco = (parseFloat(exame.precoTotalExame) - descontoPercentual);       
                    } else if(!isNaN(parseFloat((exameDesconto + "").replace(",", ".")))) {
                        exame.precoTotalExame = exame.precoTotalExame ?? exame.preco + exame.exameDesconto;

                        const desconto = parseFloat((exameDesconto + "").replace(",", "."));

                        exame.preco = (parseFloat(exame.precoTotalExame) - desconto);
                    }
                }
                else {
                    exame.precoTotalExame = exame.precoTotalExame ?? exame.preco + exame.exameDesconto;
                    exame.preco = parseFloat(exame.precoTotalExame);
                }

                exame.preco = Math.round(parseFloat(exame.preco) * 100,2) / 100;
                this.$forceUpdate();
            },                       
            abreDescricaoConvenio(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalObservacaoConvenio,
                    props: {
                        observacoesConvenio: this.observacoesConvenio,
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            resetaExibicao(){
                this.isIncluindoExame = !this.isIncluindoExame
                this.showIncluirExame = !this.showIncluirExame
                this.grupoExames = []
            },
        }
    }
</script>